import React, { FC } from "react";
import { GridFilterInputValueProps } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { Checkbox, Stack } from "@genome-frontend/uikit/build/lib/uikit";
import { CheckboxProps } from "@genome-frontend/uikit/build/lib/uikit/Checkbox";
import { CheckboxFilterOption } from "./types";

export interface CheckboxColumnFilterProps extends GridFilterInputValueProps {
  options: CheckboxFilterOption[];
}

export const CheckboxColumnFilter: FC<CheckboxColumnFilterProps> = ({
  options,
  item,
  applyValue,
}) => {
  const handleFilterChange: CheckboxProps["onChange"] = (event, checked) => {
    const name = event.target.name;
    const prevValue = item.value ?? [];
    const value = checked
      ? [...prevValue, name]
      : prevValue.filter((n: string) => n !== name);
    applyValue({ ...item, value: value.length > 0 ? value : undefined });
  };

  return (
    <Stack direction="column">
      {options.map((option) => (
        <Checkbox
          key={option.label}
          onChange={handleFilterChange}
          name={option.value}
          label={option.label}
          checked={item.value?.some((v: string) => v === option.value)}
        />
      ))}
    </Stack>
  );
};
