import React, { memo } from 'react';
import { TextField } from '@mui/material';
import { Label } from '../Label/Label';
export const TextInput = memo(({ label, size = 'small', required, ...props }) => {
    return (React.createElement("div", { style: { width: '100%' } },
        label && React.createElement(Label, { required: required },
            label,
            ":"),
        React.createElement(TextField, { size: size, placeholder: props.disabled ? '' : 'Введите значение', autoComplete: "off", ...props, sx: {
                '& .Mui-disabled': {
                    background: (theme) => theme.palette.grey['200'],
                },
                ...props.sx,
            } })));
});
