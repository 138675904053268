import React, { useMemo, useState } from 'react';
import { GridLinkOperator } from '@mui/x-data-grid';
import { Badge } from '@mui/material';
import { Typography } from '../Typography';
import { Box } from '../Box';
import { Button } from '../Button';
import { FunnelOutlineIcon } from '../../icons/FunnelOutlineIcon';
import { Popover } from '../Popover';
import { Label } from '../Label';
import { IconButton } from '../IconButton';
import { CancelIconS } from '../../icons/CancelIconS';
import { Stack } from '../Stack';
import { DataGridColumnFilterControl } from './DataGridColumnFilterControl';
import { useStoredFiltering } from './DataGridColumnHeader';
const INITIAL_FILTER_MODEL = { items: [], linkOperator: GridLinkOperator.And };
export const DataGridFilter = (props) => {
    var _a;
    const { columns } = props;
    const [filterModel, setFilterModel, clearFilter] = useStoredFiltering(props.gridKey);
    const [dialogFilterModel, setDialogFilterModel] = useState(filterModel);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setDialogFilterModel(filterModel);
    };
    const handleSubmit = (event) => {
        setFilterModel(dialogFilterModel);
        handleClose();
        event.preventDefault();
        event.stopPropagation();
        document.location.reload();
    };
    const handleClear = () => {
        setDialogFilterModel(INITIAL_FILTER_MODEL);
    };
    const handleReset = () => {
        clearFilter();
        document.location.reload();
    };
    const badge = useMemo(() => {
        var _a;
        return [...new Set((_a = filterModel === null || filterModel === void 0 ? void 0 : filterModel.items.map((item) => item.columnField)) !== null && _a !== void 0 ? _a : [])].length || null;
    }, [filterModel]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "row", spacing: 2 },
            React.createElement(Badge, { badgeContent: badge, color: "primary" },
                React.createElement(Button, { startIcon: React.createElement(FunnelOutlineIcon, null), onClick: handleClick }, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B")),
            !!((_a = filterModel === null || filterModel === void 0 ? void 0 : filterModel.items) === null || _a === void 0 ? void 0 : _a.length) && React.createElement(Button, { onClick: handleReset }, "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C \u0432\u0441\u0451")),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            } },
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Box, { sx: { p: 2 } },
                    React.createElement(Box, { sx: {
                            paddingBottom: 2,
                            borderBottom: '1px solid #DFE3E6',
                        } },
                        React.createElement(IconButton, { onClick: handleClose, sx: {
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            } },
                            React.createElement(CancelIconS, null)),
                        React.createElement(Typography, { variant: "h6" }, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B")),
                    React.createElement(Stack, { sx: { py: 2, overflowY: 'auto' }, spacing: 1 }, columns
                        .filter((column) => column.filterable)
                        .map((column) => (React.createElement(Box, { key: column.field },
                        React.createElement(Label, null,
                            column.headerName,
                            ":"),
                        React.createElement(DataGridColumnFilterControl, { column: column, formFilterModel: dialogFilterModel, setFormFilterModel: setDialogFilterModel, ...props }))))),
                    React.createElement(Stack, { sx: {
                            paddingY: 2,
                            borderTop: '1px solid #DFE3E6',
                        }, spacing: 2, direction: "row", alignItems: "center" },
                        React.createElement(Button, { color: "primary", variant: "outlined", sx: { flex: 1 }, onClick: handleClear }, "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C"),
                        React.createElement(Button, { color: "primary", variant: "contained", sx: { flex: 1 }, type: "submit" }, "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C")))))));
};
