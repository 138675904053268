import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const GrabberIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "16", height: "16", viewBox: "0 0 16 16", ...props, sx: {
            width: 16,
            height: 16,
            ...props.sx,
        } },
        React.createElement("path", { d: "M10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13Z" }),
        React.createElement("path", { d: "M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55229 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z" }),
        React.createElement("path", { d: "M7 8C7 8.55229 6.55228 9 6 9C5.44772 9 5 8.55229 5 8C5 7.44772 5.44772 7 6 7C6.55228 7 7 7.44772 7 8Z" }),
        React.createElement("path", { d: "M10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9Z" }),
        React.createElement("path", { d: "M11 4C11 4.55229 10.5523 5 10 5C9.44772 5 9 4.55229 9 4C9 3.44772 9.44772 3 10 3C10.5523 3 11 3.44772 11 4Z" }),
        React.createElement("path", { d: "M6 5C6.55228 5 7 4.55228 7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5Z" })));
};
