export const getHiddenColumnsKey = (id: string): string =>
  `${id}__HIDDEN_COLUMNS`;

export const getHiddenColumnsById = (id: string | undefined): string[] => {
  if (!id) {
    return [];
  }
  const res = localStorage.getItem(getHiddenColumnsKey(id));
  if (!res) {
    return [];
  }
  return JSON.parse(res);
};

export const setHiddenColumnsById = (
  id: string | undefined,
  hiddenColumns: string[]
) => {
  if (!id) {
    return;
  }
  localStorage.setItem(getHiddenColumnsKey(id), JSON.stringify(hiddenColumns));
};

export const getColumnsOrderKey = (id: string): string =>
  `${id}__COLUMNS_ORDER`;

export const getColumnsOrderById = (id: string | undefined): string[] => {
  if (!id) {
    return [];
  }
  const res = localStorage.getItem(getColumnsOrderKey(id));
  if (!res) {
    return [];
  }
  return JSON.parse(res);
};

export const setColumnsOrderById = (
  id: string | undefined,
  columnsOrder: string[]
) => {
  if (!id) {
    return;
  }
  localStorage.setItem(getColumnsOrderKey(id), JSON.stringify(columnsOrder));
};
