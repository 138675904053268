import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const TrashIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "16", height: "16", viewBox: "0 0 16 16", ...props, sx: {
            width: 16,
            height: 16,
            ...props.sx,
        } },
        React.createElement("g", { clipPath: "url(#clip0_4758_4471)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 1.5C6.84186 1.5 5.88809 2.37501 5.76373 3.5H10.2363C10.1119 2.37501 9.15814 1.5 8 1.5ZM8 0C6.01294 0 4.38685 1.54549 4.2582 3.5H1.75C1.33579 3.5 1 3.83579 1 4.25C1 4.66421 1.33579 5 1.75 5H14.25C14.6642 5 15 4.66421 15 4.25C15 3.83579 14.6642 3.5 14.25 3.5H11.7418C11.6132 1.54549 9.98706 0 8 0ZM3.3001 14.734C3.41521 15.463 4.04363 16 4.78174 16H11.2183C11.9564 16 12.5848 15.463 12.6999 14.734L13.8666 7.34456C13.9367 6.90111 13.5939 6.5 13.145 6.5C12.7765 6.5 12.4657 6.77437 12.42 7.13999L11.5547 14.062C11.5235 14.3122 11.3108 14.5 11.0586 14.5H4.94139C4.68923 14.5 4.47653 14.3122 4.44525 14.062L3.58 7.13999C3.5343 6.77437 3.22349 6.5 2.85503 6.5C2.40608 6.5 2.06333 6.90111 2.13335 7.34456L3.3001 14.734Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_4758_4471" },
                React.createElement("rect", { width: "16", height: "16", fill: "white" })))));
};
