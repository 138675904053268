import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const EditColumnOutlineIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "16", height: "16", viewBox: "0 0 16 16", ...props, sx: {
            width: 16,
            height: 16,
            ...props.sx,
        } },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.75 0C0.33579 0 0 0.33579 0 0.75C0 1.16421 0.33579 1.5 0.75 1.5H15.25C15.6642 1.5 16 1.16421 16 0.75C16 0.33579 15.6642 0 15.25 0H0.75ZM0.75 4C0.33579 4 0 4.33579 0 4.75C0 5.16421 0.33579 5.5 0.75 5.5H15.25C15.6642 5.5 16 5.16421 16 4.75C16 4.33579 15.6642 4 15.25 4H0.75ZM0.75 8C0.33579 8 0 8.3358 0 8.75C0 9.1642 0.33579 9.5 0.75 9.5H4C4.4142 9.5 4.75 9.1642 4.75 8.75C4.75 8.3358 4.4142 8 4 8H0.75ZM11.251 16C13.8738 16 16 13.8738 16 11.251C16 8.62816 13.8738 6.50195 11.251 6.50195C8.62816 6.50195 6.50195 8.62816 6.50195 11.251C6.50195 13.8738 8.62816 16 11.251 16ZM10.5 8.75C10.5 8.33579 10.8358 8 11.25 8C11.6642 8 12 8.33579 12 8.75V10.5L13.75 10.5C14.1642 10.5 14.5 10.8358 14.5 11.25C14.5 11.6642 14.1642 12 13.75 12H12V13.75C12 14.1642 11.6642 14.5 11.25 14.5C10.8358 14.5 10.5 14.1642 10.5 13.75V12H8.75C8.33579 12 8 11.6642 8 11.25C8 10.8358 8.33579 10.5 8.75 10.5H10.5V8.75ZM0.75 12C0.33579 12 0 12.3358 0 12.75C0 13.1642 0.33579 13.5 0.75 13.5H4C4.4142 13.5 4.75 13.1642 4.75 12.75C4.75 12.3358 4.4142 12 4 12H0.75Z" })));
};
