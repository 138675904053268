import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SortedUpIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "24", height: "24", viewBox: "0 0 24 24", ...props, sx: {
            width: 24,
            height: 24,
            ...props.sx,
        } },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.0737 6.07327L13.1772 8.96968C13.0197 9.12718 13.1313 9.39647 13.354 9.39647H15.5005V17.2501C15.5005 17.6643 15.8363 18.0001 16.2505 18.0001C16.6647 18.0001 17.0005 17.6643 17.0005 17.2501V9.39647H19.1469C19.3696 9.39647 19.4812 9.12718 19.3237 8.96968L16.4272 6.07327C16.3296 5.97557 16.1713 5.97557 16.0737 6.07327ZM4.75 9.50006C4.33579 9.50006 4 9.16428 4 8.75006C4 8.33585 4.33579 8.00006 4.75 8.00006H7.25C7.66421 8.00006 8 8.33585 8 8.75006C8 9.16428 7.66421 9.50006 7.25 9.50006H4.75ZM4 12.7501C4 13.1643 4.33579 13.5001 4.75 13.5001H10.25C10.6642 13.5001 11 13.1643 11 12.7501C11 12.3359 10.6642 12.0001 10.25 12.0001H4.75C4.33579 12.0001 4 12.3359 4 12.7501ZM4 16.7501C4 17.1643 4.33579 17.5001 4.75 17.5001H13.25C13.6642 17.5001 14 17.1643 14 16.7501C14 16.3359 13.6642 16.0001 13.25 16.0001H4.75C4.33579 16.0001 4 16.3359 4 16.7501Z" })));
};
