import React from 'react';
import { DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { CancelIconS } from '../../icons/CancelIconS';
export const ModalContent = ({ children, title, text, cancelButtonProps, confirmButtonProps, isDeleteStyle, onClose, }) => {
    return (React.createElement(React.Fragment, null,
        onClose && (React.createElement(IconButton, { onClick: onClose, sx: {
                position: 'absolute',
                right: 8,
                top: 8,
            } },
            React.createElement(CancelIconS, null))),
        React.createElement(DialogTitle, null, title),
        React.createElement(DialogContent, null, children || React.createElement(DialogContentText, null, text)),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", variant: "outlined", ...cancelButtonProps }, (cancelButtonProps === null || cancelButtonProps === void 0 ? void 0 : cancelButtonProps.children) || 'Отмена'),
            React.createElement(Button, { color: isDeleteStyle ? 'error' : 'primary', variant: "contained", type: "submit", ...confirmButtonProps }, (confirmButtonProps === null || confirmButtonProps === void 0 ? void 0 : confirmButtonProps.children) || 'Применить'))));
};
