import { useMemo } from "react";
import {
  DataGrid,
  GridEventListener,
  GridEvents,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useAppealInfoDataGrid } from "pages/ServicesPage/hooks/useAppealInfoDataGrid/useAppealInfoDataGrid";
import { useNavigate } from "react-router";
import { paths } from "router/paths";
import { useAppealInfoDataGridColumns } from "../../hooks/useAppealInfoDataGrid/useAppealInfoDataGridColumns";

export function AppealInfoDataGrid() {
  const gridData = useAppealInfoDataGrid();
  const { gridProps } = gridData;
  const columns = useAppealInfoDataGridColumns(gridData);
  const columnsMemo = useMemo(() => columns, [columns]);

  const navigate = useNavigate();
  const handleRowDoubleClick: GridEventListener<GridEvents.rowClick> = ({
    row,
  }) => {
    navigate(paths.clientService(row.id));
  };

  return (
    <DataGrid
      {...gridProps}
      columns={columnsMemo}
      onRowDoubleClick={handleRowDoubleClick}
      rowsPerPageOptions={[10, 30, 50]}
      showOrderColumn
      showColumnSettings={false}
      gridKey="appealsInfo"
    />
  );
}
