import React, { useCallback, useMemo } from 'react';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { TextInput } from '../TextInput';
export const NumberRange = ({ value, onChange, fromInputProps, toInputProps }) => {
    var _a, _b;
    const handleFromChange = useCallback((e) => {
        onChange([e.target.value ? Number(e.target.value) : null, value[1]]);
    }, [onChange, value]);
    const handleToChange = useCallback((e) => {
        onChange([value[0], e.target.value ? Number(e.target.value) : null]);
    }, [onChange, value]);
    const invalidRange = useMemo(() => {
        const [from, to] = value;
        if (from === null || from === undefined || to === null || to === undefined) {
            return false;
        }
        return Number(from) > Number(to);
    }, [value]);
    return (React.createElement(Stack, { direction: "row", alignItems: "center" },
        React.createElement(TextInput, { value: (_a = value[0]) !== null && _a !== void 0 ? _a : '', onChange: handleFromChange, type: "number", error: invalidRange, ...fromInputProps }),
        React.createElement(Box, { sx: { mx: 2 } }, " - "),
        React.createElement(TextInput, { value: (_b = value[1]) !== null && _b !== void 0 ? _b : '', onChange: handleToChange, type: "number", error: invalidRange, ...toInputProps })));
};
