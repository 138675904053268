// THIS FILE IS AUTOGENERATED. PLEASE DO NOT EDIT IT
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  UUID: any;
};

/** Адрес пользователя */
export type AddressOutputFilterInput = {
  and?: InputMaybe<Array<AddressOutputFilterInput>>;
  /** Дата конца регистрации */
  endDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
  /** Полный адрес */
  fullAddress?: InputMaybe<StringOperationFilterInput>;
  /** Идентификатор адреса */
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<AddressOutputFilterInput>>;
  /** Дата регистрации */
  startDate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
};

/** Адрес пользователя */
export type AddressSortInput = {
  /** Дата конца регистрации */
  endDate?: InputMaybe<SortEnumType>;
  /** Полный адрес */
  fullAddress?: InputMaybe<SortEnumType>;
  /** Идентификатор адреса */
  id?: InputMaybe<SortEnumType>;
  /** Дата регистрации */
  startDate?: InputMaybe<SortEnumType>;
};

export type AppealConclusionInfoOutputFilterInput = {
  and?: InputMaybe<Array<AppealConclusionInfoOutputFilterInput>>;
  /** Файл заключения */
  conclusionFile?: InputMaybe<FileDtoFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Действительность заключения */
  isActual?: InputMaybe<BooleanOperationFilterInput>;
  /** Наименование заключения */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppealConclusionInfoOutputFilterInput>>;
  /** Дата выдачи заключения специалистом */
  responseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
};

export type AppealConclusionInfoSortInput = {
  /** Файл заключения */
  conclusionFile?: InputMaybe<FileDtoSortInput>;
  id?: InputMaybe<SortEnumType>;
  /** Действительность заключения */
  isActual?: InputMaybe<SortEnumType>;
  /** Наименование заключения */
  name?: InputMaybe<SortEnumType>;
  /** Дата выдачи заключения специалистом */
  responseDate?: InputMaybe<SortEnumType>;
};

/** Информация о заявке пользователя */
export type AppealInfoOutputFilterInput = {
  and?: InputMaybe<Array<AppealInfoOutputFilterInput>>;
  /** Название заключения */
  appealTypeName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  /** Действительность заключения */
  isActual?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AppealInfoOutputFilterInput>>;
  /** Дата обращения */
  requestDate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** Дата получения */
  responseDate?: InputMaybe<ComparableNullableOfDateOnlyOperationFilterInput>;
};

/** Информация о заявке пользователя */
export type AppealInfoSortInput = {
  /** Название заключения */
  appealTypeName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Действительность заключения */
  isActual?: InputMaybe<SortEnumType>;
  /** Дата обращения */
  requestDate?: InputMaybe<SortEnumType>;
  /** Дата получения */
  responseDate?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AFTER_RESOLVER = "AFTER_RESOLVER",
  BEFORE_RESOLVER = "BEFORE_RESOLVER",
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars["Boolean"]>;
  neq?: InputMaybe<Scalars["Boolean"]>;
};

export type ClientOutputFilterInput = {
  /** Адреса пользователя */
  addresses?: InputMaybe<ListFilterInputTypeOfAddressOutputFilterInput>;
  and?: InputMaybe<Array<ClientOutputFilterInput>>;
  /** Дата рождения */
  birthdate?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** Email */
  email?: InputMaybe<StringOperationFilterInput>;
  /** Отчество */
  fathersName?: InputMaybe<StringOperationFilterInput>;
  /** ФИО */
  fio?: InputMaybe<StringOperationFilterInput>;
  /** Идентификатор пользователя */
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  /** Имя */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClientOutputFilterInput>>;
  /** Номер телефона */
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** Пол пользователя */
  sex?: InputMaybe<SexOperationFilterInput>;
  /** СНИЛС */
  snils?: InputMaybe<StringOperationFilterInput>;
  /** Фамилия */
  surname?: InputMaybe<StringOperationFilterInput>;
};

export type ClientSortInput = {
  /** Дата рождения */
  birthdate?: InputMaybe<SortEnumType>;
  /** Email */
  email?: InputMaybe<SortEnumType>;
  /** Отчество */
  fathersName?: InputMaybe<SortEnumType>;
  /** ФИО */
  fio?: InputMaybe<SortEnumType>;
  /** Идентификатор пользователя */
  id?: InputMaybe<SortEnumType>;
  /** Имя */
  name?: InputMaybe<SortEnumType>;
  /** Номер телефона */
  phoneNumber?: InputMaybe<SortEnumType>;
  /** Пол пользователя */
  sex?: InputMaybe<SortEnumType>;
  /** СНИЛС */
  snils?: InputMaybe<SortEnumType>;
  /** Фамилия */
  surname?: InputMaybe<SortEnumType>;
};

export type ComparableDateOnlyOperationFilterInput = {
  eq?: InputMaybe<Scalars["Date"]>;
  gt?: InputMaybe<Scalars["Date"]>;
  gte?: InputMaybe<Scalars["Date"]>;
  in?: InputMaybe<Array<Scalars["Date"]>>;
  lt?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
  neq?: InputMaybe<Scalars["Date"]>;
  ngt?: InputMaybe<Scalars["Date"]>;
  ngte?: InputMaybe<Scalars["Date"]>;
  nin?: InputMaybe<Array<Scalars["Date"]>>;
  nlt?: InputMaybe<Scalars["Date"]>;
  nlte?: InputMaybe<Scalars["Date"]>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<Scalars["UUID"]>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<Scalars["UUID"]>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<Scalars["Int"]>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  neq?: InputMaybe<Scalars["Int"]>;
  ngt?: InputMaybe<Scalars["Int"]>;
  ngte?: InputMaybe<Scalars["Int"]>;
  nin?: InputMaybe<Array<Scalars["Int"]>>;
  nlt?: InputMaybe<Scalars["Int"]>;
  nlte?: InputMaybe<Scalars["Int"]>;
};

export type ComparableNullableOfDateOnlyOperationFilterInput = {
  eq?: InputMaybe<Scalars["Date"]>;
  gt?: InputMaybe<Scalars["Date"]>;
  gte?: InputMaybe<Scalars["Date"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  lt?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
  neq?: InputMaybe<Scalars["Date"]>;
  ngt?: InputMaybe<Scalars["Date"]>;
  ngte?: InputMaybe<Scalars["Date"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  nlt?: InputMaybe<Scalars["Date"]>;
  nlte?: InputMaybe<Scalars["Date"]>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

export type ComparableTimeOnlyOperationFilterInput = {
  eq?: InputMaybe<Scalars["TimeSpan"]>;
  gt?: InputMaybe<Scalars["TimeSpan"]>;
  gte?: InputMaybe<Scalars["TimeSpan"]>;
  in?: InputMaybe<Array<Scalars["TimeSpan"]>>;
  lt?: InputMaybe<Scalars["TimeSpan"]>;
  lte?: InputMaybe<Scalars["TimeSpan"]>;
  neq?: InputMaybe<Scalars["TimeSpan"]>;
  ngt?: InputMaybe<Scalars["TimeSpan"]>;
  ngte?: InputMaybe<Scalars["TimeSpan"]>;
  nin?: InputMaybe<Array<Scalars["TimeSpan"]>>;
  nlt?: InputMaybe<Scalars["TimeSpan"]>;
  nlte?: InputMaybe<Scalars["TimeSpan"]>;
};

export type FileDtoFilterInput = {
  and?: InputMaybe<Array<FileDtoFilterInput>>;
  file?: InputMaybe<StringOperationFilterInput>;
  fileGuid?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FileDtoFilterInput>>;
};

export type FileDtoSortInput = {
  file?: InputMaybe<SortEnumType>;
  fileGuid?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
};

/** История изменений */
export type HistoryOutputFilterInput = {
  and?: InputMaybe<Array<HistoryOutputFilterInput>>;
  /** Отчество пользователя */
  clientFathersName?: InputMaybe<StringOperationFilterInput>;
  /** ФИО пользователя */
  clientFio?: InputMaybe<StringOperationFilterInput>;
  /** Имя пользователя */
  clientName?: InputMaybe<StringOperationFilterInput>;
  /** Фамилия пользователя */
  clientSurname?: InputMaybe<StringOperationFilterInput>;
  /** Новое значение */
  currentValue?: InputMaybe<StringOperationFilterInput>;
  /** Дата изменения данных */
  dateChanged?: InputMaybe<ComparableDateOnlyOperationFilterInput>;
  /** ФИО Администратора */
  employeeFio?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<HistoryOutputFilterInput>>;
  /** Имя организации */
  organizationName?: InputMaybe<StringOperationFilterInput>;
  /** Предыдущее значение */
  prevValue?: InputMaybe<StringOperationFilterInput>;
  /** Время изменения данных */
  timeChanged?: InputMaybe<ComparableTimeOnlyOperationFilterInput>;
  /** Момент времени изменения данных */
  trackedTime?: InputMaybe<StringOperationFilterInput>;
};

/** История изменений */
export type HistorySortInput = {
  /** Отчество пользователя */
  clientFathersName?: InputMaybe<SortEnumType>;
  /** ФИО пользователя */
  clientFio?: InputMaybe<SortEnumType>;
  /** Имя пользователя */
  clientName?: InputMaybe<SortEnumType>;
  /** Фамилия пользователя */
  clientSurname?: InputMaybe<SortEnumType>;
  /** Новое значение */
  currentValue?: InputMaybe<SortEnumType>;
  /** Дата изменения данных */
  dateChanged?: InputMaybe<SortEnumType>;
  /** ФИО Администратора */
  employeeFio?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Имя организации */
  organizationName?: InputMaybe<SortEnumType>;
  /** Предыдущее значение */
  prevValue?: InputMaybe<SortEnumType>;
  /** Время изменения данных */
  timeChanged?: InputMaybe<SortEnumType>;
  /** Момент времени изменения данных */
  trackedTime?: InputMaybe<SortEnumType>;
};

export type ListFilterInputTypeOfAddressOutputFilterInput = {
  all?: InputMaybe<AddressOutputFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<AddressOutputFilterInput>;
  some?: InputMaybe<AddressOutputFilterInput>;
};

/** Пол */
export enum Sex {
  /** Женский */
  FEMALE = "FEMALE",
  /** Мужской */
  MALE = "MALE",
}

export type SexOperationFilterInput = {
  eq?: InputMaybe<Sex>;
  in?: InputMaybe<Array<Sex>>;
  neq?: InputMaybe<Sex>;
  nin?: InputMaybe<Array<Sex>>;
};

export type SignAppealOpoAgreementInput = {
  /** Идентификатор заявки ОПО */
  appealOpoId: Scalars["Int"];
};

export enum SortEnumType {
  ASC = "ASC",
  DESC = "DESC",
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  eq?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ncontains?: InputMaybe<Scalars["String"]>;
  nendsWith?: InputMaybe<Scalars["String"]>;
  neq?: InputMaybe<Scalars["String"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nstartsWith?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type SignAppealOpoAgreementItemPayloadFragment = {
  errors: Array<{ message: string }> | null;
};

export type SignAppealOpoAgreementMutationVariables = Exact<{
  input: SignAppealOpoAgreementInput;
}>;

export type SignAppealOpoAgreementMutation = {
  signAppealOpoAgreement: { errors: Array<{ message: string }> | null };
};

export type AppealInfoItemViewFragment = {
  appealTypeName: string | null;
  id: number;
  isActual: boolean;
  requestDate: any;
};

export type UnsignedAppealOpoInfosQueryVariables = Exact<{
  order: InputMaybe<Array<AppealInfoSortInput> | AppealInfoSortInput>;
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  where: InputMaybe<AppealInfoOutputFilterInput>;
}>;

export type UnsignedAppealOpoInfosQuery = {
  unsignedAppealOpoInfos: {
    totalCount: number;
    items: Array<{
      appealTypeName: string | null;
      id: number;
      isActual: boolean;
      requestDate: any;
    }> | null;
  } | null;
};

export type ClientProfileViewFragment = {
  name: string;
  surname: string;
  fathersName: string | null;
  birthdate: any;
  snils: string;
  phoneNumber: string | null;
  email: string;
  addresses: Array<{
    fullAddress: string;
    startDate: any;
    endDate: any | null;
  }>;
};

export type ClientProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ClientProfileQuery = {
  clientProfile: {
    name: string;
    surname: string;
    fathersName: string | null;
    birthdate: any;
    snils: string;
    phoneNumber: string | null;
    email: string;
    addresses: Array<{
      fullAddress: string;
      startDate: any;
      endDate: any | null;
    }>;
  };
};

export type AppealInfoFragment = {
  appealTypeName: string | null;
  responseDate: any | null;
};

export type AppealInfoQueryVariables = Exact<{
  appealId: Scalars["Int"];
}>;

export type AppealInfoQuery = {
  appealInfo: { appealTypeName: string | null; responseDate: any | null };
};

export type AppealConclusionInfoItemFragment = {
  id: number;
  name: string;
  responseDate: any | null;
  isActual: boolean;
  conclusionFile: { fileName: string | null; file: string | null } | null;
};

export type AppealConclusionsInfosQueryVariables = Exact<{
  id: Scalars["Int"];
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<
    Array<AppealConclusionInfoSortInput> | AppealConclusionInfoSortInput
  >;
  where: InputMaybe<AppealConclusionInfoOutputFilterInput>;
}>;

export type AppealConclusionsInfosQuery = {
  appealConclusions: {
    totalCount: number;
    items: Array<{
      id: number;
      name: string;
      responseDate: any | null;
      isActual: boolean;
      conclusionFile: { fileName: string | null; file: string | null } | null;
    }> | null;
  } | null;
};

export type AppealInfoItemFragment = {
  appealTypeName: string | null;
  id: number;
  isActual: boolean;
  responseDate: any | null;
};

export type AppealsInfoQueryVariables = Exact<{
  skip: InputMaybe<Scalars["Int"]>;
  take: InputMaybe<Scalars["Int"]>;
  order: InputMaybe<Array<AppealInfoSortInput> | AppealInfoSortInput>;
  where: InputMaybe<AppealInfoOutputFilterInput>;
}>;

export type AppealsInfoQuery = {
  appealsInfo: {
    totalCount: number;
    items: Array<{
      appealTypeName: string | null;
      id: number;
      isActual: boolean;
      responseDate: any | null;
    }> | null;
  } | null;
};

export const namedOperations = {
  Query: {
    UnsignedAppealOpoInfos: "UnsignedAppealOpoInfos",
    ClientProfile: "ClientProfile",
    AppealInfo: "AppealInfo",
    AppealConclusionsInfos: "AppealConclusionsInfos",
    AppealsInfo: "AppealsInfo",
  },
  Mutation: {
    SignAppealOpoAgreement: "SignAppealOpoAgreement",
  },
  Fragment: {
    SignAppealOpoAgreementItemPayload: "SignAppealOpoAgreementItemPayload",
    AppealInfoItemView: "AppealInfoItemView",
    ClientProfileView: "ClientProfileView",
    AppealInfo: "AppealInfo",
    AppealConclusionInfoItem: "AppealConclusionInfoItem",
    AppealInfoItem: "AppealInfoItem",
  },
};
