import { IconButton } from "@genome-frontend/uikit/build/lib/uikit/IconButton/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActive from "@mui/icons-material/NotificationsActive";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  useSignAppealOpoAgreementMutation,
  useUnsignedAppealOpoInfosQuery,
} from "generated/hooks";
import {
  Checkbox,
  Modal,
  ModalContent,
} from "@genome-frontend/uikit/build/lib/uikit";
import { useToasts } from "components/Toast";
import { namedOperations } from "generated/operations";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatDate } from "utils";

export const Notifications = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const response = useUnsignedAppealOpoInfosQuery();
  const unassignedAppealOpoInfos = response.data?.unsignedAppealOpoInfos?.items;
  const [signAppealOpoAgreementMutation] = useSignAppealOpoAgreementMutation();
  const { addSuccessToast, addErrorsToast } = useToasts();
  const [selectedOpoId, setSelectedOpoId] = useState<number | null>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsCheckboxChecked(false);
  };

  const handleModalClose = () => {
    setSelectedOpoId(null);
    setIsCheckboxChecked(false);
  };

  const signIdsHandler = async () => {
    try {
      const { data } = await signAppealOpoAgreementMutation({
        variables: {
          input: {
            appealOpoId: selectedOpoId as number,
          },
        },
        refetchQueries: [namedOperations.Query.UnsignedAppealOpoInfos],
      });

      if (!data?.signAppealOpoAgreement.errors) {
        addSuccessToast("ИДС успешно подписан");
      } else {
        addErrorsToast(data?.signAppealOpoAgreement?.errors);
      }
    } catch (e: any) {
      addErrorsToast([{ message: e.message }]);
    } finally {
      setIsCheckboxChecked(false);
      handleModalClose();
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        {unassignedAppealOpoInfos?.length ? (
          <NotificationsActive sx={{ color: "white" }} />
        ) : (
          <NotificationsNoneIcon sx={{ color: "white" }} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenu-paper": {
            minWidth: "300px",
            minHeight: "400px",
          },
        }}
      >
        {unassignedAppealOpoInfos?.length ? (
          unassignedAppealOpoInfos?.map((el) => (
            <MenuItem
              onClick={() => {
                handleClose();
                setSelectedOpoId(el.id);
              }}
              key={el.id}
            >
              <Box
                sx={{
                  width: "100%",
                  border: "1px solid rgba(236, 236, 236, 0.3)",
                  padding: "0.5rem",
                  boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography>
                  {el.appealTypeName}: необходимо подписать ИДС
                </Typography>
                <Typography
                  sx={{
                    marginTop: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <CalendarTodayIcon />
                  {formatDate(el.requestDate)}
                </Typography>
              </Box>
            </MenuItem>
          ))
        ) : (
          <Typography sx={{ textAlign: "center" }}>Нет уведомлений</Typography>
        )}
      </Menu>
      <Modal open={!!selectedOpoId} onClose={handleModalClose}>
        <ModalContent
          title="ИДС"
          confirmButtonProps={{
            children: "Подписать",
            onClick: signIdsHandler,
            disabled: !isCheckboxChecked,
          }}
          cancelButtonProps={{ onClick: handleModalClose }}
        >
          <Typography>
            Для прохождения ОПО вам необходимо отметить подписание ИДС
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Checkbox
              onChange={(e) => {
                setIsCheckboxChecked(e.target.checked);
              }}
              label={"Я подписал/-а ИДС"}
              checked={isCheckboxChecked}
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
