import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const TriangleRightIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "17", height: "16", viewBox: "0 0 17 16", ...props, sx: {
            width: 17,
            height: 16,
            ...props.sx,
        } },
        React.createElement("path", { d: "M5.5 12.25C5.5 12.5494 5.6781 12.8202 5.9531 12.9387C6.2281 13.0573 6.5473 13.0008 6.765 12.7952L11.265 8.54524C11.415 8.40356 11.5 8.20633 11.5 7.99998C11.5 7.79364 11.415 7.59641 11.265 7.45472L6.765 3.2047C6.5473 2.9991 6.2281 2.9427 5.9531 3.0612C5.6781 3.1798 5.5 3.4505 5.5 3.75L5.5 12.25Z", fill: "#43484E" })));
};
