import interceptions from "generated/schema.json";
import { FieldInfo } from "./types";

type InterceptionFieldType = {
  kind: string;
  name: string;
  ofType: InterceptionFieldType | null;
};

type InterceptionField = {
  args: {
    name: string;
    description: string | null;
    type: { kind: string; name: string; ofType: InterceptionFieldType | null };
    defaultValue: string | null;
    isDeprecated: boolean;
  }[];
  deprecationReason: string | null;
  description: string | null;
  isDeprecated: boolean;
  name: string;
  type: { kind: string; name: string; ofType: InterceptionFieldType | null };
};

export const getFieldsInfo = (type: string): FieldInfo[] => {
  const queryInterception = (
    interceptions.__schema.types.find((t) => t.name === "Query")
      ?.fields as InterceptionField[]
  )?.find?.((field: any) => field.name === type);

  const dataTypeName = queryInterception?.type.name;

  const dataInterception = (
    interceptions.__schema.types.find((t) => t.name === dataTypeName)
      ?.fields as InterceptionField[]
  )?.find?.((field) => field.name === "items");

  const itemTypeName = dataInterception?.type?.ofType?.ofType?.name;

  const itemInterception = interceptions.__schema.types.find(
    (t) => t.name === itemTypeName
  );

  const whereTypeName = queryInterception?.args?.find(
    (arg) => arg.name === "where"
  )?.type?.name;

  const whereInterceptor = interceptions.__schema.types.find(
    (t) => t.name === whereTypeName
  );

  const sortTypeName = queryInterception?.args?.find(
    (arg) => arg.name === "order"
  )?.type?.ofType?.ofType?.name;

  const sortInterceptor = interceptions.__schema.types.find(
    (t) => t.name === sortTypeName
  );

  return (itemInterception?.fields ?? []).map((field) => {
    const fieldName = field.name;
    const fieldType = field?.type?.ofType?.name;

    const filterType = (whereInterceptor?.inputFields as any[])?.find(
      (inputField) => inputField.name === field.name
    )?.type?.name;

    const sortType = (sortInterceptor?.inputFields as any[])?.find(
      (inputField) => inputField.name === field.name
    )?.type?.name;

    return {
      fieldName,
      fieldType,
      filterType,
      sortType,
    };
  });
};
