export const validation = {
  MUST_HAVE_ONE_ADDRESS: "Должен быть хотя бы один адрес регистрации",
  DEREGISTRATION_DATE_MUST_BE_SET:
    "Дата снятия регистрации должна быть установлена",
  WITHDRAWAL_DATE_MUST_EARLIER_NEW_REGISTRATION:
    "Дата снятия должна наступить раньше чем дата новой регистрации",
  WITHDRAWAL_DATE_CANNOT_EARLIER_REGISTRATION:
    "Дата снятия не может быть раньше даты регистрации",
  REQUIRED: "Поле обязательно к заполнению",
  EMAIL: "Адрес электронной почты должен соответствовать формату a@a.aa",
  PHONE: "Номер телефона должен соответствовать формату +70000000000",
  SNILS: "СНИЛС должен соответствовать формату 000-000-000-00",
  FILE_SIZE: "Размер файла не должен превышать ",
};
