import { AppealConclusionInfoItemFragment } from "generated/operations";
import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { locale } from "locale";
import { renderCellWithTooltip } from "components/DataGrid/renderCellWithTooltip";
import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { FormatDateMoment } from "constants/inputDateConsts";
import moment from "moment";
import { LinkDownloadFile } from "components/LinkDownloadFile/LinkDownloadFile";
import { Link } from "@mui/material";

const fieldsOrder = ["name", "responseDate", "isActual"].reverse();

const getHeaderName = (field: string): string => {
  return (locale.conclusion as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "name":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        minWidth: 120,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({
            value,
            row,
          }: GridRenderCellParams<
            string,
            AppealConclusionInfoItemFragment
          >) => {
            if (row.conclusionFile !== null) {
              return (
                <LinkDownloadFile
                  fileData={row.conclusionFile?.file!}
                  fileName={row.conclusionFile?.fileName!}
                  title={value!}
                />
              );
            } else {
              return value;
            }
          },
        }),
      };
    case "responseDate":
      return {
        ...column,
        headerName,
        type: "dateTime",
        sortable: true,
        filterable: true,
        minWidth: 220,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({
            row,
          }: GridRenderCellParams<string, AppealConclusionInfoItemFragment>) =>
            row.responseDate !== null
              ? moment(row.responseDate).format(FormatDateMoment)
              : "",
          tooltipTransformer: ({
            row,
          }: GridRenderCellParams<string, AppealConclusionInfoItemFragment>) =>
            row.responseDate !== null
              ? moment(row.responseDate).format(FormatDateMoment)
              : "",
        }),
      };
    case "isActual":
      return {
        ...column,
        headerName,
        type: "boolean",
        sortable: true,
        filterable: true,
        renderCell: renderCellWithTooltip({
          tooltipTransformer: (params) =>
            Boolean(params.value) ? "Действительно" : "Не действительно",
          valueTransformer: ({
            value,
          }: GridRenderCellParams<
            string,
            AppealConclusionInfoItemFragment
          >) => {
            if (Boolean(value)) return <img src="/onCheckbox.ico" />;
            return <img src="/offCheckbox.ico" />;
          },
        }),
      };
    default:
      return {
        ...column,
        headerName,
        renderCell: renderCellWithTooltip(),
      };
  }
};

export const conclusionInfoColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
