import React, { memo } from 'react';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { TextInput } from '../TextInput/TextInput';
import { CircularProgress } from '../CircularProgress/CircularProgress';
const AutocompleteComponent = ({ required, size = 'small', label, error, loading, errorMessage, ...props }) => {
    return (React.createElement(MuiAutocomplete, { size: size, loading: loading, ...props, renderInput: ({ ...params }) => (
        // @ts-ignore
        React.createElement(TextInput, { required: required, label: label, error: error, helperText: errorMessage && errorMessage.name, ...params, InputProps: {
                ...params.InputProps,
                endAdornment: (React.createElement(React.Fragment, null,
                    loading ? React.createElement(CircularProgress, { color: "inherit", size: 20 }) : null,
                    params.InputProps.endAdornment)),
            } })) }));
};
export const Autocomplete = memo(AutocompleteComponent);
