import React, { useCallback, useContext } from "react";
import { ToastsContext, TToastsContext } from "./ToastsContextProvider";

interface ToastResult {
  addErrorsToast: (
    errors: { message: string | null | undefined }[] | null | undefined,
    title?: string
  ) => void;
  addErrorToast: (text?: string, title?: string) => void;
  addSuccessToast: (text?: string, title?: string) => void;
  addToast: TToastsContext["addToast"];
}

export const useToasts = (): ToastResult => {
  const { addToast } = useContext(ToastsContext);

  const addErrorsToast: ToastResult["addErrorsToast"] = useCallback(
    (errors, title = "Ошибка") => {
      addToast({
        title,
        text: errors?.map((error) => error.message ?? "").join(" "),
        severity: "error",
      });
    },
    [addToast]
  );

  const addErrorToast = useCallback(
    (text, title = "Ошибка") => {
      addToast({
        title,
        text,
        severity: "error",
      });
    },
    [addToast]
  );

  const addSuccessToast = useCallback(
    (text, title = "Успешно") => {
      addToast({
        title,
        text,
        severity: "success",
      });
    },
    [addToast]
  );

  return {
    addErrorsToast,
    addErrorToast,
    addSuccessToast,
    addToast,
  };
};
