import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { getTheme } from '../../theme/theme';
export const UIKitProvider = ({ children }) => {
    const theme = getTheme();
    return (React.createElement(MuiThemeProvider, { theme: theme },
        React.createElement(CssBaseline, null),
        children));
};
