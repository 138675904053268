import { useClientProfileQuery } from "generated/hooks";
import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Label } from "@genome-frontend/uikit/build/lib/uikit";
import { locale } from "locale";
import { CenteredCircularProgress } from "components/CenteredCircularProgress";
import { FormatDate, getAddressDate } from "utils";

interface UserProfileFormProps {}

export const ProfileForm: FC<UserProfileFormProps> = () => {
  const { data, loading } = useClientProfileQuery();

  if (!loading && !data) {
    return null;
  }

  return (
    <Box>
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Grid margin={10}>
            <Typography sx={{ mb: 2 }} variant="h6">
              Личная информация
            </Typography>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.surname}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>
                  {data?.clientProfile.surname ?? "Иванов"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.name}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.clientProfile.name ?? "Иван"}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.fathersName}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.clientProfile.fathersName}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.birthdate}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>
                  <FormatDate date={data?.clientProfile?.birthdate} />
                </Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.snils}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.clientProfile.snils}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.phoneNumber}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.clientProfile.phoneNumber}</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid xs={4} item>
                <Label>{locale.profile.email}</Label>
              </Grid>
              <Grid xs={4} item>
                <Typography>{data?.clientProfile.email}</Typography>
              </Grid>
            </Grid>

            <Typography sx={{ mb: 2, mt: 5 }} variant="h6">
              Адреса регистрации
            </Typography>

            {data?.clientProfile.addresses.map((address) => {
              return (
                <Grid
                  key={
                    address.startDate + address.endDate + new Date().getTime()
                  }
                  container
                  item
                >
                  <Grid xs={4} item>
                    <Label>{address.fullAddress}</Label>
                  </Grid>
                  <Grid xs={4} item>
                    <Typography>{getAddressDate(address)}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Box>
  );
};
