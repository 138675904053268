import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { ruRU as dataGridruRU } from '@mui/x-data-grid';
let theme = createTheme({
    spacing: 8,
    palette: {
        primary: {
            main: '#1CA3B9',
        },
    },
}, ruRU, dataGridruRU);
theme = createTheme({
    ...theme,
    components: {
        ...theme.components,
    },
});
export const getTheme = () => {
    return createTheme(theme);
};
