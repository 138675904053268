import {
  GridCellParams,
  GridFilterItem,
  GridFilterOperator,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import {
  CheckboxColumnFilter,
  CheckboxColumnFilterProps,
} from "./CheckboxColumnFilter";

export const getCheckboxFilterOptions = ({
  options,
}: Pick<GridFilterOperator, "InputComponent"> &
  Pick<CheckboxColumnFilterProps, "options">): GridFilterOperator[] => [
  {
    label: "Один из",
    value: "in",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params: GridCellParams<string>): boolean => {
        return params.value === filterItem.value;
      };
    },

    // @ts-ignore
    InputComponent: (props: any) => (
      <CheckboxColumnFilter options={options} {...props} />
    ),
    InputComponentProps: { type: "string" },
  },
];
