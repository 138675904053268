import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ApolloHelper } from "./ApolloHelper";
import { ApolloProvider } from "@apollo/client";
import { UIKitProvider } from "@genome-frontend/uikit/build/lib/uikit/UIKitProvider";

import RefreshTokenUpdater from "./RefreshTokenUpdater";

import { LoginPage } from "pages/LoginPage/LoginPage";

import { paths } from "router/paths";
import { GuestElement } from "router/GuestElement";
import { PrivateElement } from "router/PrivateElement";
import { AuthContextProvider } from "auth/AuthContextProvider";
import { ToastsContextProvider } from "components/Toast/ToastsContextProvider";
import { ConfirmContextProvider } from "components/Confirm";

import { HomePage } from "./pages/HomePage/HomePage";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import { ServicesPage } from "./pages/ServicesPage/ServicesPage";

import { ServicePage } from "./pages/ServicePage/ServicePage";
import { AccessPermissions } from "./types/AccessPermissions";
import { PermissionElement } from "./router/PermissionElement";

function App() {
  return (
    <UIKitProvider>
      <AuthContextProvider>
        <ConfirmContextProvider>
          <ToastsContextProvider>
            <ApolloProvider client={ApolloHelper.configureClient()}>
              <Router>
                <RefreshTokenUpdater />
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route
                      path={paths.login()}
                      element={
                        <GuestElement>
                          <LoginPage />
                        </GuestElement>
                      }
                    />

                    <Route
                      element={
                        <PrivateElement>
                          <Outlet />
                        </PrivateElement>
                      }
                    >
                      <Route path={paths.homePage()} element={<HomePage />} />

                      <Route
                        path={paths.clientServices()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsMedical}
                          >
                            <ServicesPage />
                          </PermissionElement>
                        }
                      />
                      <Route
                        path={paths.clientService()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsMedical}
                          >
                            <ServicePage />
                          </PermissionElement>
                        }
                      />

                      <Route
                        path={paths.profile()}
                        element={
                          <PermissionElement
                            permission={AccessPermissions.ClientsMedical}
                          >
                            <ProfilePage />
                          </PermissionElement>
                        }
                      />
                    </Route>
                  </Routes>
                </Suspense>
              </Router>
            </ApolloProvider>
          </ToastsContextProvider>
        </ConfirmContextProvider>
      </AuthContextProvider>
    </UIKitProvider>
  );
}

export default App;
