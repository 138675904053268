import React, { useEffect, useState } from 'react';
import { GridLinkOperator } from '@mui/x-data-grid';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { FunnelFilledIcon } from '../../icons/FunnelFilledIcon';
import { SortedDownIcon } from '../../icons/SortedDownIcon';
import { SortedUpIcon } from '../../icons/SortedUpIcon';
import { Popover } from '../Popover';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { DataGridColumnFilterControl } from './DataGridColumnFilterControl';
import { RadioGroup } from '../RadioButton';
const INITIAL_FILTER_MODEL = { items: [], linkOperator: GridLinkOperator.And };
const sortOptions = [
    { id: '', caption: 'По умолчанию' },
    { id: 'asc', caption: 'По возрастанию' },
    { id: 'desc', caption: 'По убыванию' },
];
const sortOptionsString = [
    { id: '', caption: 'По умолчанию' },
    { id: 'asc', caption: 'Сортировка от А до Я' },
    { id: 'desc', caption: 'Сортировка от Я до А' },
];
export const useStoredSorting = (suffix = 'default') => {
    const sortKey = `X_SORT_${suffix.toUpperCase()}`;
    const parseSafe = (x, defaultValue) => {
        var _a;
        try {
            return (_a = JSON.parse(localStorage.getItem(x))) !== null && _a !== void 0 ? _a : defaultValue;
        }
        catch (e) {
            return defaultValue !== null && defaultValue !== void 0 ? defaultValue : {};
        }
    };
    const [formSortModel, setFormSort] = useState(parseSafe(sortKey, []));
    const setFormSortModel = (x) => {
        setFormSort(x);
        localStorage.setItem(sortKey, JSON.stringify(x));
    };
    useEffect(() => {
        if (!localStorage.getItem(sortKey)) {
            localStorage.setItem(sortKey, JSON.stringify(formSortModel));
        }
    }, []);
    const cleanUp = () => {
        localStorage.removeItem(sortKey);
        setFormSort([]);
    };
    return [formSortModel, setFormSortModel, cleanUp];
};
export const useStoredFiltering = (suffix = 'default') => {
    var _a;
    const filterKey = `X_FILTER_${suffix.toUpperCase()}`;
    const parseSafe = (x, defaultValue) => {
        var _a;
        try {
            return (_a = JSON.parse(localStorage.getItem(x))) !== null && _a !== void 0 ? _a : defaultValue;
        }
        catch (e) {
            return defaultValue !== null && defaultValue !== void 0 ? defaultValue : {};
        }
    };
    const [formFilterModel, setFormFilter] = useState((_a = parseSafe(filterKey, INITIAL_FILTER_MODEL)) !== null && _a !== void 0 ? _a : INITIAL_FILTER_MODEL);
    const setFormFilterModel = (fn) => {
        var _a;
        const prevState = (_a = parseSafe(filterKey)) !== null && _a !== void 0 ? _a : INITIAL_FILTER_MODEL;
        const newState = typeof fn === 'function' ? fn(prevState) : fn;
        setFormFilter(newState);
        localStorage.setItem(filterKey, JSON.stringify(newState));
    };
    useEffect(() => {
        if (!localStorage.getItem(filterKey)) {
            localStorage.setItem(filterKey, JSON.stringify(formFilterModel));
        }
    }, [formFilterModel]);
    const cleanUp = () => {
        localStorage.removeItem(filterKey);
        setFormFilterModel(INITIAL_FILTER_MODEL);
    };
    return [formFilterModel, setFormFilterModel, cleanUp];
};
export const DataGridColumnHeader = ({ column, gridKey, ...props }) => {
    var _a, _b, _c;
    const { disableColumnFilter } = props;
    const [filterModel, setFilterModel] = useStoredFiltering(gridKey);
    const [sortModel, setSortModel] = useStoredSorting(gridKey);
    const [dialogFilterModel, setDialogFilterModel] = useState(filterModel);
    const [dialogSortModel, setDialogSortModel] = useState(sortModel);
    const sortDirection = (_a = sortModel === null || sortModel === void 0 ? void 0 : sortModel.find((s) => s.field === column.field)) === null || _a === void 0 ? void 0 : _a.sort;
    const settingsAvailable = (!disableColumnFilter && column.filterable) || column.sortable;
    const dialogSortValue = (_c = (_b = dialogSortModel === null || dialogSortModel === void 0 ? void 0 : dialogSortModel.find((s) => s.field === column.field)) === null || _b === void 0 ? void 0 : _b.sort) !== null && _c !== void 0 ? _c : '';
    const handleChangeFormSortValue = (e, value) => {
        if (!value) {
            setDialogSortModel(dialogSortModel.filter((s) => s.field !== column.field));
            return;
        }
        if (dialogSortValue) {
            setDialogSortModel(dialogSortModel.map((s) => {
                if (s.field === column.field) {
                    return {
                        ...s,
                        sort: value,
                    };
                }
                return s;
            }));
            return;
        }
        setDialogSortModel(dialogSortModel.concat({
            field: column.field,
            sort: value,
        }));
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const isFilterActive = !!(filterModel === null || filterModel === void 0 ? void 0 : filterModel.items.some((i) => i.columnField === column.field));
    const handleClickHeader = (event) => {
        if (!settingsAvailable) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setDialogFilterModel(filterModel);
        setDialogSortModel(sortModel);
        setAnchorEl(null);
    };
    const handleSubmit = (event) => {
        setFilterModel(dialogFilterModel);
        if (column.sortable) {
            setSortModel(dialogSortModel);
        }
        handleClose();
        event.preventDefault();
        event.stopPropagation();
        document.location.reload();
    };
    const handleClear = () => {
        var _a, _b;
        setFilterModel({
            ...filterModel,
            items: (_a = filterModel === null || filterModel === void 0 ? void 0 : filterModel.items.filter((item) => item.columnField !== column.field)) !== null && _a !== void 0 ? _a : [],
        });
        setSortModel((_b = sortModel === null || sortModel === void 0 ? void 0 : sortModel.filter((s) => s.field !== column.field)) !== null && _b !== void 0 ? _b : []);
        handleClose();
        document.location.reload();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { sx: {
                flex: 1,
                width: '100%',
                cursor: settingsAvailable ? 'pointer' : 'default',
            }, direction: "row", alignItems: "center", onClick: handleClickHeader },
            React.createElement(Box, { sx: {
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }, title: column.headerName }, column.headerName),
            sortDirection === 'desc' && React.createElement(SortedDownIcon, { color: "action" }),
            sortDirection === 'asc' && React.createElement(SortedUpIcon, { color: "action" }),
            !disableColumnFilter && column.filterable && React.createElement(FunnelFilledIcon, { sx: { opacity: isFilterActive ? 1 : 0.4 }, color: "action" })),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            } },
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(Box, { sx: { px: 2 } },
                    React.createElement(Stack, { sx: { py: 2 }, spacing: 2 },
                        column.sortable && (React.createElement(Box, null,
                            React.createElement(Typography, { variant: "h6" }, "\u0421\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u043A\u0430"),
                            React.createElement(RadioGroup, { options: column.type ? sortOptions : sortOptionsString, value: dialogSortValue, onChange: handleChangeFormSortValue }))),
                        !disableColumnFilter && column.filterable && (React.createElement(Box, null,
                            React.createElement(Typography, { variant: "h6" }, "\u0424\u0438\u043B\u044C\u0442\u0440"),
                            React.createElement(DataGridColumnFilterControl, { column: column, formFilterModel: dialogFilterModel, setFormFilterModel: setDialogFilterModel, ...props })))),
                    React.createElement(Stack, { sx: {
                            paddingY: 2,
                            borderTop: '1px solid #DFE3E6',
                        }, spacing: 2, direction: "row", alignItems: "center" },
                        React.createElement(Button, { color: "primary", variant: "outlined", sx: { flex: 1 }, onClick: handleClear }, "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C"),
                        React.createElement(Button, { color: "primary", variant: "contained", sx: { flex: 1 }, type: "submit" }, "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C")))))));
};
