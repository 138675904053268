import React from 'react';
import { Switch } from '@mui/material';
import { Button } from '../Button';
import { EditColumnOutlineIcon } from '../../icons/EditColumnOutlineIcon';
import { Popover } from '../Popover';
import { Stack } from '../Stack';
import { Label } from '../Label';
import { GrabberIcon } from '../../icons/GrabberIcon';
export const ColumnSettings = ({ columns, hiddenColumns, onChangeHiddenColumns, columnsOrder, onChangeColumnsOrder, }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getColumnVisibility = (column) => !(hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns.includes(column.field));
    const handleChangeColumnVisibility = (column, visible) => {
        if (visible) {
            onChangeHiddenColumns === null || onChangeHiddenColumns === void 0 ? void 0 : onChangeHiddenColumns((hiddenColumns !== null && hiddenColumns !== void 0 ? hiddenColumns : []).filter((hiddenColumn) => hiddenColumn !== column.field));
        }
        else {
            onChangeHiddenColumns === null || onChangeHiddenColumns === void 0 ? void 0 : onChangeHiddenColumns((hiddenColumns !== null && hiddenColumns !== void 0 ? hiddenColumns : []).concat(column.field));
        }
    };
    const reversedColumnOrder = [...(columnsOrder !== null && columnsOrder !== void 0 ? columnsOrder : [])].reverse();
    const columnsForView = columns
        .filter((column) => column.type !== 'actions')
        .sort((left, right) => {
        return reversedColumnOrder.indexOf(right.field) - reversedColumnOrder.indexOf(left.field);
    });
    const handleDragStart = (event, column) => {
        event.dataTransfer.setData('text', column.field);
    };
    const handleDrop = (event, column) => {
        event.preventDefault();
        const draggableColumnField = event.dataTransfer.getData('text');
        const prevColumnOrder = columnsForView.map((c) => c.field);
        const newColumnOrder = prevColumnOrder.filter((field) => field !== draggableColumnField);
        const dropIndex = newColumnOrder.findIndex((field) => field === column.field);
        newColumnOrder.splice(dropIndex, 0, draggableColumnField);
        onChangeColumnsOrder === null || onChangeColumnsOrder === void 0 ? void 0 : onChangeColumnsOrder(newColumnOrder);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { key: "upload", onClick: handleClick, startIcon: React.createElement(EditColumnOutlineIcon, null) }, "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0442\u0430\u0431\u043B\u0438\u0446\u044B"),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            } },
            React.createElement(Stack, { sx: { p: 2 } }, columnsForView.map((column) => (React.createElement(Stack, { key: column.field, onDragStart: (event) => handleDragStart(event, column), onDragOver: (event) => {
                    event.preventDefault();
                }, onDrop: (event) => handleDrop(event, column), direction: "row", alignItems: "center", justifyContent: "space-between", spacing: 1, draggable: true },
                React.createElement(Stack, { direction: "row", alignItems: "center", spacing: 1 },
                    React.createElement(GrabberIcon, { color: "action", sx: { cursor: 'move' } }),
                    React.createElement(Label, null, column.headerName)),
                React.createElement(Switch, { checked: getColumnVisibility(column), onChange: (event, value) => {
                        handleChangeColumnVisibility(column, value);
                    } }))))))));
};
