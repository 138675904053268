import React, { useMemo } from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { DataGridHeader } from './DataGridHeader';
import { DataGridColumnHeader } from './DataGridColumnHeader';
export const DataGrid = (props) => {
    const { columns, hiddenColumns, columnsOrder, orderColumnIndex, showOrderColumn, hideHeader, page, pageSize } = props;
    const formattedColumns = useMemo(() => {
        const reversedColumnOrder = [...(columnsOrder !== null && columnsOrder !== void 0 ? columnsOrder : [])].reverse();
        const res = props.columns
            // Все колонки, которых нет в columnsOrder идут в конец
            .sort((left, right) => {
            return reversedColumnOrder.indexOf(right.field) - reversedColumnOrder.indexOf(left.field);
        })
            .map((column) => ({
            ...column,
            filterable: false,
            renderHeader: () => React.createElement(DataGridColumnHeader, { gridKey: props.gridKey, column: column, ...props }),
            sortable: false,
            disableColumnMenu: true,
            hide: hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns.includes(column.field),
        }));
        if (showOrderColumn) {
            const field = {
                field: 'orderColumn',
                headerName: '№',
                width: 50,
                type: 'number',
                renderCell: (params) => {
                    return (page !== null && page !== void 0 ? page : 0) * (pageSize !== null && pageSize !== void 0 ? pageSize : 0) + params.api.getRowIndex(params.id) + 1;
                },
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
            };
            res.splice(orderColumnIndex || 0, 0, field);
        }
        return res;
    }, [columns, hiddenColumns, columnsOrder, props.filterModel, props.sortModel]);
    return (React.createElement(Box, { sx: { height: '100%', display: 'flex', flexDirection: 'column' } },
        !hideHeader && React.createElement(DataGridHeader, { ...props }),
        React.createElement(Box, { sx: { flex: 1 } },
            React.createElement(Box, { sx: { height: '100%' } },
                React.createElement(MuiDataGrid, { ...props, columns: formattedColumns, filterModel: undefined, onFilterModelChange: undefined, disableColumnFilter: true, sx: {
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        ...props.sx,
                    }, 
                    // Для фикса бага с зависанием скролла при изменении количества строк
                    disableVirtualization: true })))));
};
