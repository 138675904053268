import { GridFilterModel } from "@mui/x-data-grid";
import { FilterListType } from "types/filterGraphQLTypes";
import { FilterInput, WhereTransformer } from "../components/DataGrid";

const getOperatorValue = (operator: string | undefined): string => {
  if (operator) {
    if (operator === "is") {
      return "in";
    }
    return operator;
  } else {
    return "contains";
  }
};

export const whereTransformer: (gridKey: string) => WhereTransformer =
  (gridKey: string) => (whereInputs: any) => {
    const localFilter: GridFilterModel = JSON.parse(
      localStorage.getItem("X_FILTER_" + gridKey.toUpperCase())!
    );

    if (!localFilter) {
      return whereInputs;
    }

    const data = localFilter.items
      .filter((x) => x.columnField)
      .reduce<FilterInput>((prev, cur) => {
        if (
          cur.operatorValue === FilterListType.Some ||
          cur.operatorValue === FilterListType.All ||
          cur.operatorValue === FilterListType.None
        ) {
          prev[cur.columnField] = {
            [getOperatorValue(cur.operatorValue)]: { eq: cur.value },
          };
        } else {
          prev[cur.columnField] = {
            [getOperatorValue(cur.operatorValue)]: cur.value,
          };
        }
        return prev;
      }, {});
    return data;
  };
