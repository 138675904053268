import { ColumnTransformer } from "components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { locale } from "locale";
import { renderCellWithTooltip } from "components/DataGrid/renderCellWithTooltip";
import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { AppealInfoItemFragment } from "generated/operations";
import { FormatDateMoment } from "constants/inputDateConsts";
import moment from "moment";
import { Link } from "@mui/material";
import { paths } from "router/paths";

const fieldsOrder = ["appealTypeName", "responseDate", "isActual"].reverse();

const getHeaderName = (field: string): string => {
  return (locale.appeal as { [key: string]: string })[field] ?? field;
};

export const transformColumn = (column: GridColDef): GridColDef => {
  const headerName = getHeaderName(column.field);

  switch (column.field) {
    case "appealTypeName":
      return {
        ...column,
        headerName,
        sortable: true,
        filterable: true,
        minWidth: 120,
        renderCell: ({
          value,
          row,
        }: GridRenderCellParams<string, AppealInfoItemFragment>) => {
          return (
            <Link
              href={paths.clientService(row.id)}
              sx={{
                whiteSpace: "normal",
              }}
            >
              {value}
            </Link>
          );
        },
      };
    case "responseDate":
      return {
        ...column,
        headerName,
        type: "dateTime",
        sortable: true,
        filterable: true,
        minWidth: 220,
        renderCell: renderCellWithTooltip({
          valueTransformer: ({
            row,
          }: GridRenderCellParams<string, AppealInfoItemFragment>) =>
            row.responseDate !== null
              ? moment(row.responseDate).format(FormatDateMoment)
              : "",
          tooltipTransformer: ({
            row,
          }: GridRenderCellParams<string, AppealInfoItemFragment>) =>
            row.responseDate !== null
              ? moment(row.responseDate).format(FormatDateMoment)
              : "",
        }),
      };
    case "isActual":
      return {
        ...column,
        headerName,
        type: "boolean",
        sortable: true,
        filterable: true,
        renderCell: renderCellWithTooltip({
          tooltipTransformer: (params) =>
            Boolean(params.value) ? "Действительно" : "Не действительно",
          valueTransformer: ({
            value,
          }: GridRenderCellParams<string, AppealInfoItemFragment>) => {
            if (Boolean(value)) return <img src="/onCheckbox.ico" />;
            return <img src="/offCheckbox.ico" />;
          },
        }),
      };
    default:
      return {
        ...column,
        headerName,
        renderCell: renderCellWithTooltip(),
      };
  }
};

export const appealInfoColumnTransformer: ColumnTransformer = (columns) => {
  return columns
    .filter((column) => fieldsOrder.includes(column.field))
    .sort((left, right) => {
      return fieldsOrder.indexOf(right.field) - fieldsOrder.indexOf(left.field);
    })
    .map((column) => transformColumn(column));
};
