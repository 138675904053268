import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const TriangleLeftIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "17", height: "16", viewBox: "0 0 17 16", ...props, sx: {
            width: 17,
            height: 16,
            ...props.sx,
        } },
        React.createElement("path", { d: "M11.5 3.75002C11.5 3.45057 11.3219 3.17981 11.0469 3.06128C10.7719 2.94274 10.4527 2.99915 10.235 3.20476L5.735 7.45476C5.585 7.59644 5.5 7.79367 5.5 8.00002C5.5 8.20636 5.585 8.40359 5.735 8.54528L10.235 12.7953C10.4527 13.0009 10.7719 13.0573 11.0469 12.9388C11.3219 12.8202 11.5 12.5495 11.5 12.25V3.75002Z", fill: "#43484E" })));
};
