import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const FunnelOutlineIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "16", height: "16", viewBox: "0 0 16 16", ...props, sx: {
            width: 16,
            height: 16,
            ...props.sx,
        } },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.27014 1.5C1.59796 1.5 1.26518 2.31614 1.74571 2.78616L5.82523 6.77648C6.25799 7.19978 6.50192 7.7796 6.50192 8.38496V13.747C6.50192 14.462 7.40746 14.7715 7.84507 14.206L9.34878 12.2628C9.45047 12.1314 9.50564 11.97 9.50564 11.8038V8.38539C9.50564 7.77965 9.74988 7.1995 10.1831 6.77615L14.266 2.78642C14.7469 2.3165 14.4142 1.5 13.7418 1.5H2.27014ZM0.696838 3.85848C-0.744749 2.44841 0.253592 0 2.27014 0H13.7418C15.7589 0 16.757 2.44951 15.3144 3.85924L11.2315 7.84898C11.087 7.99009 11.0056 8.18348 11.0056 8.38539V11.8038C11.0056 12.3023 10.8401 12.7866 10.5351 13.1808L9.03136 15.124C7.71855 16.8205 5.00192 15.8921 5.00192 13.747V8.38496C5.00192 8.18317 4.92061 7.9899 4.77636 7.8488L0.696838 3.85848Z" })));
};
