import { useState, useMemo, useCallback, ReactNode } from "react";
import {
  DataGridProps,
  GridFilterModel,
  GridSortModel,
  GridSelectionModel,
  GridColumns,
  GridRenderCellParams,
} from "@genome-frontend/uikit/build/lib/uikit/DataGrid";

import { QueryResult } from "@apollo/client/react/types/types";
import { ApolloError, QueryHookOptions } from "@apollo/client";
import { getColumnByFieldInfo } from "./getColumnByFieldInfo";
import {
  ColumnTransformer,
  DataTransformer,
  FilterInput,
  SortingTransformer,
  SortInput,
  VariablesTransformer,
  WhereTransformer,
} from "./types";
import {
  getDynamicFilterValue,
  getFilterValueByColumn,
} from "./getFilterValueByColumn";
import { getFieldsInfo } from "./getFieldsInfo";
import {
  getColumnsOrderById,
  getHiddenColumnsById,
  setColumnsOrderById,
  setHiddenColumnsById,
} from "./localStorageHelpers";

export interface IRowsState<T> {
  page: number;
  pageSize: number;
  filter?: GridFilterModel;
  sorting?: GridSortModel;
}

export interface UseGqlDataGridParams<TData, TRow, TVariables> {
  id?: string;
  type: string;
  queryHook: (
    options: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>;
  onGraphqlError?: (error: ApolloError) => void | undefined;
  defaultSort?: GridSortModel;
  dataTransformer?: DataTransformer<TData, TRow>;
  columnTransformer?: ColumnTransformer;
  sortingTransformer?: SortingTransformer;
  whereTransformer?: WhereTransformer;
  variablesTransformer?: VariablesTransformer<TVariables>;
  selection?: boolean;
  dynamicColumns?: string[];
  getDynamicColumnValue?: (
    params: GridRenderCellParams<string, TRow>,
    field: string
  ) => ReactNode;
}

export interface UseGqlDataGridResult<TData, TRow, TVariables> {
  refetch: () => void;
  resetSelection: () => void;
  selectionModel: GridSelectionModel;
  rows: TRow[];
  ids: number[];
  setIds: (ids: number[]) => void;
  variables: TVariables;
  gridProps: Pick<
    DataGridProps,
    | "filterMode"
    | "filterModel"
    | "onFilterModelChange"
    | "sortingMode"
    | "onSortModelChange"
    | "sortModel"
    | "paginationMode"
    | "onPageChange"
    | "onPageSizeChange"
    | "page"
    | "pageSize"
    | "rows"
    | "rowCount"
    | "loading"
    | "onSelectionModelChange"
    | "checkboxSelection"
    | "columns"
    | "disableSelectionOnClick"
    | "initialState"
    | "hiddenColumns"
    | "onChangeHiddenColumns"
    | "columnsOrder"
    | "onChangeColumnsOrder"
  >;
}

export const useGqlDataGrid = <TData, TRow, TVariables>({
  id,
  type,
  queryHook,
  onGraphqlError,
  defaultSort,
  dataTransformer,
  columnTransformer,
  sortingTransformer,
  whereTransformer,
  variablesTransformer,
  selection,
  dynamicColumns,
  getDynamicColumnValue,
}: UseGqlDataGridParams<TData, TRow, TVariables>): UseGqlDataGridResult<
  TData,
  TRow,
  TVariables
> => {
  const [ids, setIds] = useState<number[]>([]);

  const fieldsInfo = useMemo(() => getFieldsInfo(type), [type]);

  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    getHiddenColumnsById(id)
  );
  const [columnsOrder, setColumnsOrder] = useState<string[]>(
    getColumnsOrderById(id)
  );

  const columns: GridColumns = useMemo(() => {
    const rawColumns: GridColumns =
      fieldsInfo?.map((field) => {
        return getColumnByFieldInfo(field);
      }) ?? [];

    if (dynamicColumns) {
      dynamicColumns.forEach((dynamicColumn) => {
        rawColumns.push({
          field: dynamicColumn,
          headerName: dynamicColumn,
          flex: 1,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<string, TRow>) => {
            return (
              getDynamicColumnValue &&
              getDynamicColumnValue(params, dynamicColumn)
            );
          },
          filterable: true,
          sortable: false,
        });
      });
    }
    return columnTransformer ? columnTransformer(rawColumns) : rawColumns;
  }, [columnTransformer, dynamicColumns, getDynamicColumnValue, fieldsInfo]);

  const [rowsState, setRowsState] = useState<IRowsState<TData>>({
    page: 0,
    pageSize: 10,
    sorting: defaultSort,
  });

  const onFilterModelChange = (filter: GridFilterModel) => {
    setRowsState((prev) => ({ ...prev, filter, page: 0 }));
  };

  const onSortModelChange = (sorting: GridSortModel) => {
    setRowsState((prev) => ({ ...prev, sorting }));
  };

  const onPageChange = (page: number) => {
    setRowsState((prev) => ({ ...prev, page }));
  };

  const onPageSizeChange = (pageSize: number) => {
    setRowsState((prev) => ({ ...prev, pageSize }));
  };

  const { page, pageSize } = rowsState;

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const onSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  const selectionProps = selection
    ? {
        checkboxSelection: true,
        disableSelectionOnClick: true,
        onSelectionModelChange,
        selectionModel,
      }
    : {};

  const resetSelection = () => {
    setSelectionModel([] as GridSelectionModel);
  };

  const order = useMemo(() => {
    const sortingSource = rowsState.sorting?.length
      ? rowsState.sorting
      : defaultSort;

    const sortInput: SortInput[] =
      sortingSource?.map(
        (sortingItem) =>
          ({
            [sortingItem.field]: sortingItem.sort?.toUpperCase(),
          } as SortInput)
      ) ?? [];
    return sortingTransformer ? sortingTransformer(sortInput) : sortInput;
  }, [rowsState.sorting, sortingTransformer, defaultSort]);

  const where = useMemo(() => {
    const rawWhere: FilterInput =
      rowsState.filter?.items.reduce((acc, filter) => {
        const fieldInfo = fieldsInfo.find(
          (f) => f.fieldName === filter.columnField
        );
        if (fieldInfo) {
          const filterValue = getFilterValueByColumn(filter, fieldInfo);
          if (filter.columnField in acc) {
            return {
              ...acc,
              and: {
                ...acc.and,
                [filter.columnField]: filterValue,
              },
            };
          }
          if (filterValue) {
            return {
              ...acc,
              [filter.columnField]: filterValue,
            };
          }
        }
        return acc;
      }, {} as FilterInput) ?? {};

    if (ids.length) {
      rawWhere.id = {
        in: ids,
      };
    }

    const parsedWhere = whereTransformer
      ? whereTransformer(rawWhere)
      : rawWhere;
    if (parsedWhere && Object.keys(parsedWhere).length) {
      return parsedWhere;
    }
    return undefined;
  }, [rowsState.filter, fieldsInfo, whereTransformer, ids]);

  const dynamicFilter = useMemo(() => {
    const rawDynamicFilter = rowsState.filter?.items
      .filter((filter) => dynamicColumns?.includes(filter.columnField))
      .map((filter) => ({
        code: filter.columnField,
        filterValue: getDynamicFilterValue(filter),
      }));
    return rawDynamicFilter?.length ? rawDynamicFilter : undefined;
  }, [rowsState.filter, dynamicColumns]);

  const skip = page * pageSize;
  const take = pageSize;

  const variables = useMemo(() => {
    const rawVariables = {
      skip,
      take,
      order,
      where,
      ...(dynamicFilter && {
        input: {
          filters: dynamicFilter,
        },
      }),
    };
    return variablesTransformer
      ? variablesTransformer(rawVariables as any as TVariables)
      : rawVariables;
  }, [skip, take, order, where, variablesTransformer, dynamicFilter]);

  const { loading, data, refetch } = queryHook({
    variables: variables as any as TVariables,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError: onGraphqlError,
  });

  const defaultDataTransformer: DataTransformer<TData, TRow> = useCallback(
    (d) => ({
      rows: (d as any)?.[type]?.items ?? [],
      rowCount: (d as any)?.[type]?.totalCount ?? 0,
    }),
    [type]
  );

  const { rows, rowCount } = useMemo(() => {
    if (!data) {
      return {
        rows: [] as any[],
        rowCount: 0,
      };
    }

    return dataTransformer
      ? dataTransformer(data)
      : defaultDataTransformer(data);
  }, [data, dataTransformer, defaultDataTransformer]);

  const setIdsHandler = useCallback((newIds: number[]) => {
    setIds(newIds);
    resetSelection();
  }, []);

  const onChangeHiddenColumns = useCallback(
    (newHiddenColumns: string[]) => {
      setHiddenColumns(newHiddenColumns);
      setHiddenColumnsById(id, newHiddenColumns);
    },
    [id, setHiddenColumns]
  );

  const onChangeColumnsOrder = useCallback(
    (newColumnsOrder: string[]) => {
      setColumnsOrder(newColumnsOrder);
      setColumnsOrderById(id, newColumnsOrder);
    },
    [id, setColumnsOrder]
  );

  return {
    resetSelection,
    refetch,
    selectionModel,
    rows,
    ids,
    setIds: setIdsHandler,
    variables: {
      skip,
      take,
      order,
      where,
    } as any as TVariables,
    gridProps: {
      filterMode: "server",
      filterModel: rowsState.filter,
      onFilterModelChange,
      sortingMode: "server",
      onSortModelChange,
      sortModel: rowsState.sorting,
      paginationMode: "server",
      onPageChange,
      onPageSizeChange,
      disableSelectionOnClick: true,
      page,
      pageSize,
      rows,
      rowCount,
      loading,
      columns,
      hiddenColumns,
      onChangeHiddenColumns,
      columnsOrder,
      onChangeColumnsOrder,
      ...selectionProps,
    },
  };
};
