export const getPageNumbers = (pageSize, totalCount) => {
    if (!totalCount) {
        return [1];
    }
    const pageNumbers = [];
    const lastPage = totalCount ? Math.floor(totalCount / pageSize) : 1;
    for (let i = 1; i <= lastPage; i++) {
        pageNumbers.push(i);
    }
    return pageNumbers;
};
