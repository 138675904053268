let pendingRequests: ((retry: boolean) => void)[] = [];

const onUnauthenticatedError: (() => void)[] = [];
export const subscribeToUnauthenticatedErrorResponse = (
  handler: () => void
) => {
  onUnauthenticatedError.push(handler);
  return onUnauthenticatedError.length - 1;
};

export const unsubscribeFromUnauthenticatedErrorResponse = (
  handlerDescriptor: number
) => {
  onUnauthenticatedError.splice(handlerDescriptor, 1);
};

export const resolvePendingRequests = (retry: boolean) => {
  pendingRequests.forEach((callback) => callback(retry));
  pendingRequests = [];
};

export const handleUnauthenticatedError = (): Promise<boolean> => {
  onUnauthenticatedError.forEach((handler) => handler());

  return new Promise((resolve) =>
    pendingRequests.push((retry: boolean) => resolve(retry))
  );
};
