import React, { FC } from "react";
import { Box, CircularProgress } from "@genome-frontend/uikit/build/lib/uikit";

export const CenteredCircularProgress: FC = ({}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
