import { GridColDef } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { FieldInfo } from "./types";
import { locale } from "../../locale";
import { formatDate } from "utils/formatDate";
import { getCheckboxFilterOptions } from "../CheckboxColumnFilter";

export const getColumnByFieldInfo = (field: FieldInfo): GridColDef => {
  const commonProps: GridColDef = {
    field: field.fieldName,
    headerName: field.fieldName,
    minWidth: 150,
    filterable: !!field.filterType,
    sortable: !!field.sortType,
  };

  if (field.fieldType && field.fieldType in locale.enums) {
    const options = Object.entries(locale.enums[field.fieldType]).map(
      ([value, label]) => ({ value, label })
    );
    commonProps.filterOperators = getCheckboxFilterOptions({ options });
  }

  switch (field.fieldType) {
    case "Int":
      return {
        ...commonProps,
        type: "number",
      };
    case "Date":
    case "DateTime":
      return {
        ...commonProps,
        type: "date",
        renderCell: ({ value }) => {
          if (String(value)) {
            return value ? formatDate(value) : undefined;
          } else {
            return value ? value.toString() : undefined;
          }
        },
      };
    default:
      return {
        flex: 1,
        ...commonProps,
      };
  }
};
