export const ListDateColumnType: string = "List<Date>";
export const ListBooleanColumnType: string = "List<Boolean>";

// Тип фильтрации элементов списка Hot chocolate
export enum FilterListType {
  All = "all", // Все элементы списка должны удовлетворять условию переданного фильтра (фильтр передается)
  Some = "some", // Хотя бы один из элементов списка должен удовлетворять условию переданного фильтра (фильтр передается)
  None = "none", // Ни один из элементов списка не должен удовлетворять условию переданного фильтра (фильтр передается)
  Any = "any", // Cтатус наличие элементов в списке (если передается true) или статус пустого списка если передается false (фильтр непередается)
}
