import React, { useState } from "react";
import { AccessPermissions } from "types/AccessPermissions";
import { TokenHelper } from "TokenHelper";

export enum AuthStateEnum {
  UNKNOWN = "UNKNOWN",
  LOADING = "LOADING",
  AUTHORIZED = "AUTHORIZED",
  UNAUTHORIZED = "UNAUTHORIZED",
}

const LS_AUTH_STATE_KEY = "frmo/authState";
const DEFAULT_AUTH_STATE = AuthStateEnum.UNAUTHORIZED;

const initialAuthState =
  localStorage.getItem(LS_AUTH_STATE_KEY) || DEFAULT_AUTH_STATE;
const initialAccessPermissions = TokenHelper.getUserAccesses();

export const AuthContext = React.createContext({
  state: initialAuthState,
  setState: (state: AuthStateEnum) => {},
  hasAccessPermission: (permission: AccessPermissions) =>
    initialAccessPermissions.includes(permission),
});

type TProps = {
  children: React.ReactElement;
};

export const AuthContextProvider = ({ children }: TProps) => {
  const [state, setState] = useState(initialAuthState);

  const [accessPermissions, setAccessPermissions] = useState(
    initialAccessPermissions
  );

  const hasAccessPermission = (permission: AccessPermissions) => {
    return accessPermissions.includes(permission);
  };

  const setStateHandler = (value: AuthStateEnum) => {
    setState(value);
    localStorage.setItem(LS_AUTH_STATE_KEY, value);
    setAccessPermissions(TokenHelper.getUserAccesses());
  };

  return (
    <AuthContext.Provider
      value={{ state, setState: setStateHandler, hasAccessPermission }}
    >
      {children}
    </AuthContext.Provider>
  );
};
