import dateFnsFormat from "date-fns/format";
import { useMemo } from "react";

export type Format = "default" | "server" | "short";

const mapFormatToTemplate = (format: Format) => {
  if (format === "server") return "yyyy.MM.dd";
  if (format === "short") return "yyyy.MM";
  return "dd.MM.yyyy";
};

export const formatDate = (date: string, format: Format = "default") => {
  return dateFnsFormat(new Date(date), mapFormatToTemplate(format));
};

export const FormatDate = ({
  date,
  format,
}: {
  date: string;
  format?: Format;
}) => {
  const result = useMemo(() => formatDate(date, format), [date, format]);
  return <>{result}</>;
};
