import { Link } from "@mui/material";

export interface LinkDownloadFileProps {
  fileData: string;
  fileName: string;
  title: string;
}

export function LinkDownloadFile({
  fileData,
  fileName,
  title,
}: LinkDownloadFileProps) {
  const fileHref = `data:$application/octet-stream;base64,${fileData}`;

  return (
    <Link download={fileName} href={fileHref}>
      {title}
    </Link>
  );
}
