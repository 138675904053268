export class MaskExpress {
  static phone: string = "+7 (999) 999-99-99";

  static snils: string = "999-999-999-99";

  public static ToPhoneNumber(phone: string): string {
    const numberPhone = phone
      .replaceAll("+7", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "");
    return numberPhone;
  }
}
