import { Address } from "pages/ProfilePage/types";

export const getAddressDate = (adr: Address): string => {
  if (adr.endDate != null) {
    return (
      adr.startDate.substring(0, 4) +
      "г. - " +
      adr.endDate.substring(0, 4) +
      "г. "
    );
  } else {
    return adr.startDate.substring(0, 4) + "г. - н.в.";
  }
};
