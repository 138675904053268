import React, { memo } from 'react';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { IconButton } from '../IconButton/IconButton';
import { TriangleLeftIcon } from '../../icons/TriangleLeftIcon';
import { TriangleRightIcon } from '../../icons/TriangleRightIcon';
import { getPageNumbers } from './utils';
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 30, 50];
export const Pagination = memo(({ totalCount, onChangePageSize, onChangePageNumber, currentPageNumber = 1, currentPageSize = DEFAULT_PAGE_SIZE }) => {
    const countPages = totalCount ? Math.floor(totalCount / currentPageSize) : 1;
    const isFirstPage = currentPageNumber === 1;
    const isLastPage = totalCount <= currentPageSize * currentPageNumber;
    const startPosition = totalCount ? (currentPageNumber - 1) * currentPageSize + 1 : 0;
    const endPosition = isLastPage ? totalCount : startPosition + currentPageSize - 1;
    const pageNumbers = getPageNumbers(currentPageSize, totalCount);
    const handleChangePageNumber = (event) => {
        onChangePageNumber(Number(event.target.value));
    };
    const handleChangePageSize = (event) => {
        onChangePageSize(Number(event.target.value));
    };
    const handlePrevPageClick = () => {
        const prevPage = currentPageNumber - 1;
        if (prevPage > 0) {
            onChangePageNumber(prevPage);
        }
    };
    const handleNextPageClick = () => {
        const nextPage = currentPageNumber + 1;
        if (nextPage <= countPages) {
            onChangePageNumber(nextPage);
        }
    };
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 4,
            border: '1px solid #DFE3E6',
            borderTop: 0,
        } },
        React.createElement(Box, { sx: { flex: 1 } },
            React.createElement(Typography, { sx: { fontSize: 14 } },
                startPosition,
                " - ",
                endPosition,
                " \u0438\u0437 ",
                totalCount)),
        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' } },
            React.createElement(IconButton, { onClick: handlePrevPageClick, disabled: isFirstPage },
                React.createElement(TriangleLeftIcon, null)),
            React.createElement(FormControl, null,
                React.createElement(Select, { value: String(currentPageNumber), onChange: handleChangePageNumber, sx: { width: 60 } }, pageNumbers.map((pageNumber) => (React.createElement(MenuItem, { key: pageNumber, value: pageNumber }, pageNumber))))),
            React.createElement(Typography, { sx: { color: '#4A5157', fontSize: 14, marginLeft: 2 } },
                "\u0438\u0437 ",
                countPages),
            React.createElement(IconButton, { onClick: handleNextPageClick, disabled: isLastPage },
                React.createElement(TriangleRightIcon, null))),
        React.createElement(Box, { sx: { flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' } },
            React.createElement(Typography, { sx: { fontSize: 12, marginRight: 2, color: '#76818A' } }, "\u041F\u043E\u043A\u0430\u0437\u044B\u0432\u0430\u0442\u044C \u043F\u043E:"),
            React.createElement(FormControl, null,
                React.createElement(Select, { value: String(currentPageSize), onChange: handleChangePageSize, sx: { width: 60 } }, PAGE_SIZES.map((pageSize) => (React.createElement(MenuItem, { key: pageSize, value: pageSize }, pageSize))))))));
});
