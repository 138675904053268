import { useLocation, Navigate } from "react-router-dom";

type TProps = {
  to: string;
  replace?: boolean;
};

export const Redirect = ({ to, replace }: TProps) => {
  const location = useLocation();
  return <Navigate to={to} state={{ from: location, replace }} />;
};
