import { TGqlError } from "types/error";
import { handleUnauthenticatedError } from "./handleUnauthenticatedError";

export const handleGraphqlError = async ({
  code,
  next,
}: TGqlError): Promise<boolean> => {
  switch (code) {
    case "AUTH_NOT_AUTHENTICATED":
      return handleUnauthenticatedError();
    default:
      return next();
  }
};
