import { common } from "./common";
import { enums } from "./enums";
import { profile } from "./profile";
import { client } from "./client";
import { validation } from "./validation";
import { appeal } from "./appeal";
import { conclusion } from "./conclusion";

export const ruLocale = {
  ...common,
  enums,
  profile,
  client,
  validation,
  appeal,
  conclusion,
};
