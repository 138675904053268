import { FC, useMemo } from "react";
import { DataGrid } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { useConclusionInfoDataGrid } from "pages/ServicePage/hooks/useConclusionInfoDataGrid/useConclusionInfoDataGrid";
import { useConclusionInfoDataGridColumns } from "pages/ServicePage/hooks/useConclusionInfoDataGrid/useConclusionInfoDataGridColumns";
import { ApolloError } from "@apollo/client";

export interface TConclusionInfoGridProps {
  id: number;
  onGraphqlNotFoundError?: ((error: ApolloError) => void) | undefined;
}

export const ConclusionInfoDataGrid: FC<TConclusionInfoGridProps> = ({
  id,
  onGraphqlNotFoundError,
}) => {
  const gridData = useConclusionInfoDataGrid({
    variablesTransformer: (variables) => {
      variables.id = id!;
      return variables;
    },
    onGraphqlError: onGraphqlNotFoundError,
  });
  const { gridProps } = gridData;
  const columns = useConclusionInfoDataGridColumns(gridData);
  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <DataGrid
      {...gridProps}
      columns={columnsMemo}
      rowsPerPageOptions={[10, 30, 50]}
      showOrderColumn
      showColumnSettings={false}
      gridKey="appealConclusions"
    />
  );
};
