import { GridRenderCellParams } from "@genome-frontend/uikit/build/lib/uikit/DataGrid";
import { Box } from "@genome-frontend/uikit/build/lib/uikit";

type RenderCellWithTooltipParams<TData> = {
  tooltipTransformer?: (
    params: GridRenderCellParams<string, TData>
  ) => string | undefined;
  valueTransformer?: (
    params: GridRenderCellParams<string, TData>
  ) => JSX.Element | string | undefined;
};

export const renderCellWithTooltip = <TData,>(
  renderCellWithTooltipParams?: RenderCellWithTooltipParams<TData>
) => {
  return (params: GridRenderCellParams<string, TData>) => {
    return (
      <Box
        title={
          renderCellWithTooltipParams?.tooltipTransformer
            ? renderCellWithTooltipParams.tooltipTransformer(params)
            : params.value ?? ""
        }
      >
        {renderCellWithTooltipParams?.valueTransformer
          ? renderCellWithTooltipParams.valueTransformer(params)
          : params.value ?? ""}
      </Box>
    );
  };
};
