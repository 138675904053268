import { MainLayout } from "components/MainLayout";
import { AppealInfoDataGrid } from "./components/AppealInfoGrid/AppealInfoGird";

export function ServicesPage() {
  return (
    <MainLayout title="Мои заявки">
      <AppealInfoDataGrid />
    </MainLayout>
  );
}
