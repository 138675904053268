import { useAuth } from "auth/useAuth";
import { AccessPermissions } from "types/AccessPermissions";
import { paths } from "./paths";
import { Redirect } from "./Redirect";
import React from "react";

interface PermissionElementProps {
  permission: AccessPermissions;
  children: React.ReactElement;
}

export const PermissionElement = ({
  permission,
  children,
}: PermissionElementProps) => {
  const { hasAccessPermission } = useAuth();

  if (hasAccessPermission(permission)) return children;
  else return <Redirect to={paths.login()} />;
};
