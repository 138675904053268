import React, { useContext } from "react";

import { AuthContext, AuthStateEnum } from "./AuthContextProvider";

type TProps = {
  children: React.ReactElement;
  fallback: React.ReactElement;
  isShouldBeAuthorized?: boolean;
};

export const AuthBoundaryContainer = ({
  children,
  fallback = <></>,
  isShouldBeAuthorized = true,
}: TProps): React.ReactElement | null => {
  const { state: authState } = useContext(AuthContext);

  if (authState === AuthStateEnum.AUTHORIZED) {
    if (isShouldBeAuthorized) return children;
    return fallback;
  }

  if (authState === AuthStateEnum.UNAUTHORIZED) {
    if (isShouldBeAuthorized) return fallback;
    return children;
  }

  return null;
};
