import { MainLayout } from "components/MainLayout";
import { ConclusionInfoDataGrid } from "./components/ConclusionInfoDataGrid/ConclusionInfoDataGrid";
import { useNavigate, useParams } from "react-router";
import { useAppealInfoQuery } from "generated/hooks";
import { ApolloError } from "@apollo/client";
import { paths } from "router/paths";
import { useToasts } from "components/Toast";
import moment from "moment/moment";
import { FormatDateMoment } from "constants/inputDateConsts";
import { Label } from "@mui/icons-material";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { id } from "date-fns/locale";

export function ServicePage() {
  const params = useParams();

  const appealId = Number(params.id);

  const navigate = useNavigate();
  const { addErrorsToast } = useToasts();

  const onGraphqlNotFoundError = (error: ApolloError) => {
    const errorMessage =
      error.graphQLErrors.length > 0 &&
      error.graphQLErrors[0].extensions.code === "NotFound"
        ? error.message
        : "Не удалось загрузить информацию по заявке";

    navigate(paths.clientServices());
    addErrorsToast([{ message: errorMessage }]);
  };

  const { data } = useAppealInfoQuery({
    variables: {
      appealId,
    },
    onError: onGraphqlNotFoundError,
  });

  const appealTitleBlank = [data?.appealInfo.appealTypeName];
  if (data && data.appealInfo.responseDate)
    appealTitleBlank.push(
      moment(data.appealInfo.responseDate).format(FormatDateMoment)
    );
  const appealTitle = appealTitleBlank.join(", ");

  return (
    <MainLayout
      breadcrumps={[{ name: "Мои услуги", to: paths.clientServices() }]}
      currentPage={appealTitle}
    >
      <ConclusionInfoDataGrid
        id={appealId}
        onGraphqlNotFoundError={onGraphqlNotFoundError}
      />
    </MainLayout>
  );
}
