import React, { Fragment } from 'react';
import { Typography } from '../Typography';
import { Stack } from '../Stack';
import { DataGridFilter } from './DataGridFilter';
import { ColumnSettings } from './ColumnSettings';
export const DataGridHeader = (props) => {
    const { headerTitle, headerButtons, showColumnSettings } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "row", alignItems: "center", sx: { py: 2 } },
            headerTitle && React.createElement(Typography, { variant: "h6" }, headerTitle),
            !props.disableColumnFilter && React.createElement(DataGridFilter, { ...props }),
            React.createElement(Stack, { direction: "row", alignItems: "center", sx: { ml: 'auto' }, spacing: 2 },
                showColumnSettings && React.createElement(ColumnSettings, { ...props }), headerButtons === null || headerButtons === void 0 ? void 0 :
                headerButtons.map((headerButton, index) => (React.createElement(Fragment, { key: index }, headerButton)))))));
};
