import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SortedDownIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "24", height: "24", viewBox: "0 0 24 24", ...props, sx: {
            width: 24,
            height: 24,
            ...props.sx,
        } },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.4263 17.9268L19.3228 15.0304C19.4802 14.8729 19.3687 14.6036 19.146 14.6036H16.9995L16.9995 6.75C16.9995 6.33578 16.6637 6 16.2495 6C15.8353 6 15.4995 6.33578 15.4995 6.75L15.4995 14.6036L13.3531 14.6036C13.1304 14.6036 13.0188 14.8729 13.1763 15.0304L16.0728 17.9268C16.1704 18.0245 16.3287 18.0245 16.4263 17.9268ZM4 7.75C4 7.33579 4.33579 7 4.75 7H13.25C13.6642 7 14 7.33579 14 7.75C14 8.16421 13.6642 8.5 13.25 8.5H4.75C4.33579 8.5 4 8.16421 4 7.75ZM4 11.75C4 11.3358 4.33579 11 4.75 11H10.25C10.6642 11 11 11.3358 11 11.75C11 12.1642 10.6642 12.5 10.25 12.5H4.75C4.33579 12.5 4 12.1642 4 11.75ZM4.75 15C4.33579 15 4 15.3358 4 15.75C4 16.1642 4.33579 16.5 4.75 16.5H7.25C7.66421 16.5 8 16.1642 8 15.75C8 15.3358 7.66421 15 7.25 15H4.75Z" })));
};
