type Id = string | number;

export const paths = {
  homePage: () => "/",
  login: () => "/login",
  profile: () => "/profile",

  clientServices: () => "/services",
  clientService: (id: Id = ":id") => `/services/${id}`,
};
