import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ChevronRightSIcon } from "@genome-frontend/uikit/build/lib/icons/ChevronRightSIcon";
import { Header } from "../Header";
import {
  Box,
  Paper,
  Stack,
  Link,
  Typography,
  Divider,
} from "@genome-frontend/uikit/build/lib/uikit";

type TMainLayoutProps = {
  title?: React.ReactNode;
  breadcrumps?: { name: string; to: string }[];
  children?: React.ReactNode;
  currentPage?: React.ReactNode;
};

export const MainLayout = ({
  title,
  breadcrumps,
  children,
  currentPage,
}: TMainLayoutProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Header />

      <Box sx={{ backgroundColor: "#0D424E", flex: 1 }}>
        <Paper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 0,
            overflow: "hidden",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)}`,
          }}
        >
          {breadcrumps && (
            <Stack spacing={0.5} alignItems="center" direction="row">
              {breadcrumps.map((el) => {
                return (
                  <Fragment key={el.name}>
                    <RouterLink to={el.to}>
                      <Link
                        sx={{ color: "#76818A" }}
                        key={el.name}
                        underline="none"
                      >
                        {el.name}
                      </Link>
                    </RouterLink>

                    <ChevronRightSIcon />
                  </Fragment>
                );
              })}
              <Link sx={{ color: "#76818A" }} underline="none">
                {currentPage}
              </Link>
            </Stack>
          )}

          {title && (
            <Typography sx={{ mb: 2 }} variant="h6">
              {title}
            </Typography>
          )}

          <Divider />
          <Box sx={{ flex: 1 }}>{children}</Box>
        </Paper>
      </Box>
    </Box>
  );
};
