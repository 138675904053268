export const client: { [key: string]: string } = {
  name: "Имя",
  surname: "Фамилия",
  fathersName: "Отчество",
  sex: "Пол",
  sexMale: "Мужской",
  sexFemale: "Женский",
  birthdate: "Дата рождения",
  snils: "Номер СНИЛС",
  phoneNumber: "Телефон",
  email: "Email",
};
