import { AuthBoundaryContainer } from "../auth/AuthBoundaryContainer";

import { paths } from "./paths";
import { Redirect } from "./Redirect";
import React from "react";

type TProps = {
  children: React.ReactElement;
};

export const PrivateElement = ({ children }: TProps) => (
  <AuthBoundaryContainer fallback={<Redirect to={paths.login()} />}>
    {children}
  </AuthBoundaryContainer>
);
