export const profile: { [key: string]: string } = {
  name: "Имя",
  surname: "Фамилия",
  fathersName: "Отчество",
  birthdate: "Дата рождения",
  sex: "Пол",
  sexMale: "Мужской",
  sexFemale: "Женский",
  snils: "СНИЛС",
  phoneNumber: "Телефон",
  email: "Email",
};
