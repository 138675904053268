import React, { useState, FC, useCallback } from "react";
import {
  Toast,
  TToastProps,
} from "@genome-frontend/uikit/build/lib/uikit/Toast";

const DEFAULT_HIDE_DURATION = 5000;

export type TToastsContext = {
  addToast: (toast: TToastProps) => void;
};

export const ToastsContext = React.createContext<TToastsContext>({
  addToast: () => {},
});

export const ToastsContextProvider: FC = ({ children }) => {
  const [toasts, setToasts] = useState<TToastProps[]>([]);

  const addToast = useCallback(
    (toast: TToastProps) => {
      setToasts(() => [toast]);
    },
    [setToasts]
  );

  const closeHandler = useCallback(() => {
    setToasts([]);
  }, [setToasts]);

  return (
    <ToastsContext.Provider value={{ addToast }}>
      {children}
      {toasts.map((toast) => (
        <Toast
          open
          onClose={closeHandler}
          autoHideDuration={DEFAULT_HIDE_DURATION}
          {...toast}
        />
      ))}
    </ToastsContext.Provider>
  );
};
