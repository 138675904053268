import React from 'react';
import { Checkbox as MuiCheckbox, FormLabel } from '@mui/material';
export const Checkbox = ({ label, ...props }) => {
    return (React.createElement(FormLabel, { sx: { display: 'flex', alignItems: 'center' } },
        React.createElement(MuiCheckbox
        // tmp fix
        // checkedIcon={<CheckboxCheckedIcon />}
        // indeterminateIcon={<CheckboxIndeterminateIcon />}
        // icon={<CheckboxUncheckedIcon />}
        , { ...props }),
        label));
};
