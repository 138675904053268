import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import { IconButton, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { parseHintFromNode } from '../../helpers/parseHintFromNode';
import { HelpIcon } from '../../icons/HelpIcon';
import { Popover } from '../Popover/Popover';
export const Label = ({ children, required, help, hint, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const title = hint !== null && hint !== void 0 ? hint : parseHintFromNode(children);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormLabel, { title: title, sx: { display: 'flex', alignItems: 'center' }, ...props },
            React.createElement(Box, { sx: {
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                } }, children),
            required && (React.createElement("span", { style: { display: 'inline-block', marginLeft: 4, fontSize: 24, verticalAlign: 'middle', fontWeight: 'bold', color: '#FA4547' } }, "*")),
            help && (React.createElement(IconButton, { size: "small", onClick: handleClick },
                React.createElement(HelpIcon, null)))),
        React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            } },
            React.createElement(Typography, { sx: { p: 1 } }, help))));
};
