import React, { memo } from 'react';
import { Select as MuiSelect, MenuItem } from '@mui/material';
import { Label } from '../Label/Label';
export const Select = memo(({ label, required, options, size = 'small', children, ...props }) => {
    return (React.createElement("div", { style: { width: '100%' } },
        label && React.createElement(Label, { required: required }, label),
        React.createElement(MuiSelect, { size: size, ...props, sx: { width: '100%' } },
            options &&
                options.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label))),
            children)));
});
