import React, { useState, FC, useCallback } from "react";
import {
  Modal,
  TModalContentProps,
  ModalContent,
} from "@genome-frontend/uikit/build/lib/uikit/Modal";

export interface ConfirmProps {
  getConfirmProps: (close: () => void) => TModalContentProps;
}

export type TConfirmContext = {
  addConfirm: (confirm: ConfirmProps) => void;
};

export const ConfirmContext = React.createContext<TConfirmContext>({
  addConfirm: () => {},
});

export const ConfirmContextProvider: FC = ({ children }) => {
  const [modals, setModals] = useState<ConfirmProps[]>([]);

  const addConfirm = useCallback(
    (confirm: ConfirmProps) => {
      setModals(() => [confirm]);
    },
    [setModals]
  );

  const close = useCallback(() => {
    setModals([]);
  }, [setModals]);

  return (
    <ConfirmContext.Provider value={{ addConfirm }}>
      {children}
      {modals.map((modal) => {
        const props = modal.getConfirmProps(close);
        return (
          <Modal open onClose={props.onClose}>
            <ModalContent {...props} />
          </Modal>
        );
      })}
    </ConfirmContext.Provider>
  );
};
