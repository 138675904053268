export const AuthHelper = {
  async sendFetchRequest(body: URLSearchParams): Promise<Response> {
    const authenticationPath = "connect/token";

    const authenticationURL =
      process.env.REACT_APP_AUTHORIZATION_ENDPOINT_URL ??
      `${location.protocol}//${window.location.hostname}/${authenticationPath}`;

    if (!authenticationURL) {
      throw Error("REACT_APP_AUTHORIZATION_ENDPOINT_URL empty");
    }
    return fetch(authenticationURL!, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: body,
    });
  },
};
