import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import { useAppealConclusionsInfosQuery } from "generated/hooks";
import {
  AppealConclusionInfoItemFragment,
  AppealConclusionsInfosQuery,
  AppealConclusionsInfosQueryVariables,
} from "generated/operations";
import { sortingTransformer } from "utils/sortingTransformer";
import { whereTransformer } from "utils/whereTransformer";
import { conclusionInfoColumnTransformer } from "./utils/useConclusionInfoColumnTransformer";

export type TConclusionInfoDataGridProps = Partial<
  UseGqlDataGridParams<
    AppealConclusionsInfosQuery,
    AppealConclusionInfoItemFragment,
    AppealConclusionsInfosQueryVariables
  >
>;

export type TConclusionInfoDataGridResult = UseGqlDataGridResult<
  AppealConclusionsInfosQuery,
  AppealConclusionInfoItemFragment,
  AppealConclusionsInfosQueryVariables
>;

export const useConclusionInfoDataGrid = (
  props?: TConclusionInfoDataGridProps
): TConclusionInfoDataGridResult => {
  const dynamicColumns = new Array<string>();
  const gridName = "appealConclusions";

  return useGqlDataGrid<
    AppealConclusionsInfosQuery,
    AppealConclusionInfoItemFragment,
    AppealConclusionsInfosQueryVariables
  >({
    type: gridName,
    queryHook: useAppealConclusionsInfosQuery,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    columnTransformer: conclusionInfoColumnTransformer,
    dynamicColumns,
    ...props,
  });
};
