import { Header } from "../../components/Header";
import { Box } from "@mui/material";
import { ProfileForm } from "./components/ProfileForm/ProfileForm";

export const ProfilePage = () => {
  return (
    <Box>
      <Header />
      <ProfileForm />
    </Box>
  );
};
