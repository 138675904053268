export const parseHintFromNode = (children) => {
    if (typeof children === 'string')
        return children;
    let result = '';
    if (Array.isArray(children)) {
        for (const child of children) {
            if (typeof child !== 'string')
                return '';
            result += child;
        }
    }
    return result;
};
