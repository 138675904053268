import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const FunnelFilledIcon = (props) => {
    return (React.createElement(SvgIcon, { width: "24", height: "24", viewBox: "0 0 24 24", ...props, sx: {
            width: 24,
            height: 24,
            ...props.sx,
        } },
        React.createElement("path", { d: "M11.3201 17.8166L13.3201 16.1499C13.4341 16.0549 13.5 15.9142 13.5 15.7658V13.1469C13.5 13.051 13.5276 12.9571 13.5794 12.8765L17.5048 6.77038C17.7187 6.43762 17.4797 6 17.0842 6H6.91583C6.52025 6 6.28133 6.43762 6.49524 6.77038L10.4206 12.8765C10.4724 12.9571 10.5 13.051 10.5 13.1469V17.4325C10.5 17.8564 10.9944 18.088 11.3201 17.8166Z" })));
};
