import {
  useGqlDataGrid,
  UseGqlDataGridParams,
  UseGqlDataGridResult,
} from "components/DataGrid";
import { useAppealsInfoQuery } from "generated/hooks";
import {
  AppealsInfoQuery,
  AppealsInfoQueryVariables,
  AppealInfoItemFragment,
} from "generated/operations";
import { sortingTransformer } from "utils/sortingTransformer";
import { whereTransformer } from "utils/whereTransformer";
import { appealInfoColumnTransformer } from "./utils/useAppealInfoColumnTransformer";

export type TAppealsInfoDataGridProps = Partial<
  UseGqlDataGridParams<
    AppealsInfoQuery,
    AppealInfoItemFragment,
    AppealsInfoQueryVariables
  >
>;

export type TAppealsInfoDataGridResult = UseGqlDataGridResult<
  AppealsInfoQuery,
  AppealInfoItemFragment,
  AppealsInfoQueryVariables
>;

export const useAppealInfoDataGrid = (
  props?: TAppealsInfoDataGridProps
): TAppealsInfoDataGridResult => {
  const dynamicColumns = new Array<string>();
  const gridName = "appealsInfo";

  const result = useGqlDataGrid<
    AppealsInfoQuery,
    AppealInfoItemFragment,
    AppealsInfoQueryVariables
  >({
    type: gridName,
    queryHook: useAppealsInfoQuery,
    sortingTransformer: sortingTransformer(gridName),
    whereTransformer: whereTransformer(gridName),
    columnTransformer: appealInfoColumnTransformer,
    dynamicColumns,
    ...props,
  });

  return result;
};
